import { Box, CircularProgress, Typography } from '@mui/material';

interface ICountdown {
  remainingTime: number;
  progress: number;
}

export const Countdown = ({ remainingTime, progress }: ICountdown) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{ color: (theme) => theme.palette.grey[300] }}
      />
      <CircularProgress
        sx={{ position: 'absolute' }}
        variant="determinate"
        value={progress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {remainingTime}m
        </Typography>
      </Box>
    </Box>
  );
};
