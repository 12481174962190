import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Step,
  StepButton,
  Stepper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Div100vh, { use100vh } from 'react-div-100vh';
import { useNavigate } from 'react-router-dom';
import { useSessionContext, useServiceProvider } from 'src/common';
import { useNotificationDrawerContext } from 'src/common/notification-drawer-context-provider';
import { useProductsContext } from 'src/common/products-context-provider';
import { useSignalR } from 'src/hooks/use-signal-r/useSignalR';
import { useProductViewingsService } from 'src/services';
import { ItemType } from 'src/types';
import { PendingWrapper } from '../viewings/components/PendingWrapper';
import { SwipableNotificationDrawer } from '../viewings/components/SwipableNotificationDrawer';
import AddProductsContainer from './add-products';
import { RequestNavigationBar } from './components/RequestNavigationBar';
import ReturnProductsContainer from './return-products';
import SubmitRequestsContainer from './submit-requests';
import useSound from 'use-sound';
import NotificationSound from '../../sounds/NotificationSound.mp3';
import { useSessionService } from '../../services/session-service';

export const RequestFlowContainer = () => {
  const steps = ['Return Products', 'Add Products', 'Submit Request'];
  const {
    onHoldProducts,
    returningProducts,
    pendingProducts,
    currentProducts,
    clearRequestItems,
    setCurrentProducts
  } = useProductsContext();

  const { clearLocalStorage, sessionId, location } = useSessionContext();
  const { updateProductViewingForSubmit, getCurrentProductViewings } =
    useProductViewingsService();
  const { drawerOpen, toggleDrawer, notificationCount, setNotificationCount } =
    useNotificationDrawerContext();

  const { subscription } = useSignalR(location);
  const [activeStep, setActiveStep] = useState(() => {
    if (pendingProducts.length > 0 || currentProducts.length === 0) {
      return 1;
    }

    return 0;
  });
  const isShortScreen = useMediaQuery('(max-height:900px)');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { getSessionById } = useSessionService();
  const { stopListening } = useServiceProvider();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [play] = useSound(NotificationSound as string);

  const clearCustomerData = async () => {
    await clearRequestItems(true);
  };

  useEffect(() => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };

    window.onunload = async (event) => {
      event.preventDefault();
      await clearCustomerData();
      navigate('/viewings', { replace: true });
      return;
    };

    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const viewings = await getCurrentProductViewings(sessionId);
      setCurrentProducts(viewings);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscription !== null) {
      play();
      enqueueSnackbar('New products from your subscriptions are available!', {
        variant: 'info'
      });
    }
  }, [enqueueSnackbar, play, subscription]);

  useEffect(() => {
    (async () => {
      const sessionCheck = await getSessionById(sessionId);
      if (sessionCheck.sessionStatusId === 3) {
        clearLocalStorage();
        await stopListening(location, sessionId);
      }
    })();
  });

  const getSteps = (step: number) => {
    switch (step) {
      case 0:
      default:
        return <ReturnProductsContainer />;
      case 1:
        return <AddProductsContainer isShortScreen={isShortScreen} />;
      case 2:
        return <SubmitRequestsContainer isShortScreen={isShortScreen} />;
    }
  };

  const getTitles = (step: number) => {
    switch (step) {
      case 0:
      default:
        return 'Return Products';
      case 1:
        return 'Add Products';
      case 2:
        return 'Submit Request';
    }
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      await handleSubmit();
    } else {
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const productsToSubmit = [
      ...onHoldProducts.map((product) => ({
        sessionId,
        productId: product.productId,
        productItemType: ItemType.RequestItem
      })),
      ...returningProducts.map((product) => ({
        sessionId,
        productId: product.productId,
        productItemType: ItemType.ReturnItem
      }))
    ];

    const response = (await updateProductViewingForSubmit({
      productViewings: productsToSubmit,
      sessionId,
      locationName: location
    })) as AxiosResponse;

    if (response.status < 300 && response.status >= 200) {
      await clearRequestItems();
      enqueueSnackbar('Request submitted successfully', {
        variant: 'success'
      });
      navigate('/viewings', { replace: true });
    } else {
      await clearRequestItems(true);
      const message = get(
        response,
        'response.data',
        'There was an error submitting your request. Please click cancel and create a new request'
      ) as string;
      enqueueSnackbar(message, {
        variant: 'error',
        persist: true,
        action: (snackbarId) => (
          <Button
            onClick={() => {
              closeSnackbar(snackbarId);
            }}
          >
            Dismiss
          </Button>
        )
      });
    }
    setIsLoading(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const height = use100vh();

  return (
    <Div100vh>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Container
          sx={{
            height: `calc(${height ? `${height}px` : '100vh'} - ${
              isShortScreen ? '80px' : '100px'
            })`
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <Box px={10}>
              <IconButton
                onClick={toggleDrawer(true)}
                sx={{
                  float: 'right',
                  marginTop: isShortScreen ? 1 : 3,
                  marginBottom: 0,
                  marginRight: 1
                }}
              >
                <Badge
                  badgeContent={notificationCount}
                  color="secondary"
                  sx={{ margin: 0, padding: 0, marginLeft: 'auto' }}
                >
                  <NotificationsIcon
                    sx={{ color: '#9e9e9e', margin: 0, padding: 0 }}
                  />
                </Badge>
              </IconButton>
              <Grid
                container
                paddingTop={isShortScreen ? 0 : 1}
                paddingBottom={isShortScreen ? 1 : 4}
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Typography
                    color={'primary'}
                    variant={isShortScreen ? 'h5' : 'h4'}
                    sx={{ fontWeight: 'medium' }}
                  >
                    {pendingProducts.length > 0
                      ? 'View Catalogue'
                      : 'Create Request'}
                  </Typography>
                  <PendingWrapper>
                    <Typography
                      variant={isShortScreen ? 'h6' : 'h5'}
                      sx={{ fontWeight: 'light' }}
                      color={'primary'}
                    >
                      Step {activeStep + 1}: {getTitles(activeStep)}
                    </Typography>
                  </PendingWrapper>
                </Grid>

                <Grid item xs={1} />

                <PendingWrapper>
                  <Grid item xs={7}>
                    <Box
                      sx={{ border: 1, borderColor: '#12233A' }}
                      pt={isShortScreen ? 2 : 4}
                      pb={isShortScreen ? 2 : 4}
                      pl={1}
                      pr={1}
                    >
                      <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <StepButton
                              color="inherit"
                              id={`stepper-step-${index}`}
                              onClick={() => setActiveStep(index)}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  </Grid>
                </PendingWrapper>
              </Grid>
              <Divider />
            </Box>

            <Box
              sx={{
                height: '100%',
                overflow: 'auto'
              }}
              paddingLeft={10}
              paddingRight={10}
              paddingTop={isShortScreen ? 1 : 2}
            >
              {getSteps(activeStep)}
            </Box>
          </Box>
        </Container>
        <Box>
          <RequestNavigationBar
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            clearCustomerData={clearCustomerData}
            isLoading={isLoading}
            compact={isShortScreen}
          />
          <SwipableNotificationDrawer
            open={drawerOpen}
            toggleDrawer={toggleDrawer}
            setNotificationCount={setNotificationCount}
            subscription={subscription}
          />
        </Box>
      </Box>
    </Div100vh>
  );
};

export default RequestFlowContainer;
