import { createContext, FC, useContext, useState } from 'react';

interface INotificationDrawerContextProvider {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  drawerOpen: boolean;
  setNotificationCount: (notificationCount: number) => void;
  notificationCount: number;
}

const NotificationDrawerContext = createContext<
  INotificationDrawerContextProvider | undefined
>(undefined);

export const NotificationDrawerContextProvider: FC = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  return (
    <NotificationDrawerContext.Provider
      value={{
        toggleDrawer,
        drawerOpen,
        setNotificationCount,
        notificationCount
      }}
    >
      {children}
    </NotificationDrawerContext.Provider>
  );
};

export const useNotificationDrawerContext = () => {
  const notificationDrawerContext = useContext(NotificationDrawerContext);

  if (!notificationDrawerContext) {
    throw new Error(
      'NotificationDrawer Context cannot be used outside of NotificationDrawer Context Provider'
    );
  }

  return notificationDrawerContext;
};
