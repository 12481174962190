import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useEnv } from 'src/common';
import { Authentication } from 'src/types/models/Authentication.type';
import { v4 as uuid } from 'uuid';

export const useAuthenticationService = () => {
  const { authenticationApiUrl } = useEnv();

  const authenticationApi = useMemo(
    () =>
      axios.create({
        baseURL: authenticationApiUrl
      }),
    [authenticationApiUrl]
  );

  const authenticate = useCallback(
    async (
      token: string,
      strategy: string,
      key?: string
    ): Promise<Authentication> => {
      const response: AxiosResponse<Authentication> =
        await authenticationApi.post(
          '/api/Authentication/authenticate',
          {
            strategy: strategy,
            token: token,
            key: key ?? uuid()
          },
          {
            headers: {
              accept: 'text/plain',
              contentType: 'application/json'
            }
          }
        );
      return response.data;
    },
    [authenticationApi]
  );

  return {
    authenticate
  };
};
