import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { useProductsContext } from 'src/common/products-context-provider';
import { Product } from 'src/types/models/Product.type';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useProductNotificationService } from 'src/services/product-notification-service';
import { useSnackbar } from 'notistack';
import { useSessionContext } from 'src/common';
import { PendingWrapper } from 'src/modules/viewings/components/PendingWrapper';

interface ProductListItemProps {
  product: Product;
  compact: boolean;
  viewImageButton?: ReactNode;
}
export const ProductListItem = ({
  product,
  compact,
  viewImageButton
}: ProductListItemProps) => {
  const {
    selectedProducts,
    addSelectedProduct,
    removeProductFromSelection,
    removeOnHoldProduct,
    isInOnHoldList,
    currentProductSlots,
    isInNotificationList,
    removeProductNotification,
    addProductNotification
  } = useProductsContext();
  const { sessionId } = useSessionContext();

  const { enqueueSnackbar } = useSnackbar();

  const [isNotificationLoading, setIsNotificationLoading] = useState(false);

  const { createProductNotification, deleteProductNotification } =
    useProductNotificationService();

  const isOnHoldForCustomer = isInOnHoldList(product.productId);

  const isNotificationList = isInNotificationList(product.productId);

  const hasProductSelected = selectedProducts.some(
    (selected) => selected.productId === product.productId
  );

  const hasNotificationActive =
    (product.isNotificationActive && isNotificationList) ||
    product.isNotificationActive;

  const shouldBeChecked =
    isOnHoldForCustomer || (product.isAvailable && hasProductSelected);

  if (!product.isAvailable && hasProductSelected && !isOnHoldForCustomer) {
    removeProductFromSelection(product.productId, true);
  }

  const [checked, setChecked] = useState(shouldBeChecked);

  const [isNotificationActive, setIsNotificationActive] = useState(
    hasNotificationActive
  );

  const handleNotificationActiveToggle = async (productName: string) => {
    setIsNotificationLoading(true);
    const newNotificationActive = !isNotificationActive;

    try {
      if (!newNotificationActive) {
        await deleteProductNotification(sessionId, product.productId);
        removeProductNotification(product.productId);
        enqueueSnackbar(`Unsubscribed from a notification for ${productName}`, {
          variant: 'info'
        });
      } else {
        await createProductNotification(sessionId, product.productId);
        addProductNotification(product);
        enqueueSnackbar(`Subscribed to a notification for ${productName}`, {
          variant: 'info'
        });
      }
      setIsNotificationActive(!isNotificationActive);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setIsNotificationLoading(false);
  };

  const handleCheckboxChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);

    if (product) {
      if (newChecked) {
        addSelectedProduct(product);
      } else {
        removeProductFromSelection(product.productId);
        removeOnHoldProduct(product.productId);
        product.isAvailable = true;
      }
    }
  };

  const getNotificationIcon = () => {
    if (isNotificationActive) {
      return (
        <NotificationsActiveIcon
          sx={{ color: (theme) => theme.palette.secondary.main }}
        />
      );
    } else {
      return <NotificationsOffIcon />;
    }
  };

  if (!product) return null;
  return (
    <Box
      sx={{
        borderBottom: '2px solid #dedede',
        paddingTop: compact ? 1 : 2,
        paddingBottom: compact ? 1 : 2
      }}
    >
      <Grid container rowSpacing={1}>
        <PendingWrapper>
          <Grid item xs={2}>
            <Box sx={{ height: '100%' }} display="flex" alignItems="center">
              <Checkbox
                style={{ transform: 'scale(1.5)' }}
                checked={shouldBeChecked}
                indeterminate={!product.isAvailable && !shouldBeChecked}
                onChange={handleCheckboxChange}
                disabled={
                  (!product.isAvailable && !shouldBeChecked) ||
                  (currentProductSlots === 0 && !shouldBeChecked)
                }
              />
            </Box>
          </Grid>
        </PendingWrapper>
        <Grid item xs>
          <Box>
            <Typography variant="subtitle2" component="h1" fontWeight={500}>
              {product.productName}
            </Typography>
            <Typography variant="caption" component="h1" color={'#8a8a8a'}>
              Lot {product.lotNumber}
            </Typography>
            <Typography variant="subtitle2" component="h1" fontWeight={500}>
              Status:{' '}
              {product.isAvailable || isOnHoldForCustomer
                ? 'Available'
                : 'Unavailable'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            pr={2}
            sx={{ width: '100%', height: '100%' }}
          >
            {viewImageButton}
            <IconButton
              onClick={() =>
                handleNotificationActiveToggle(product.productName)
              }
            >
              {isNotificationLoading ? (
                <CircularProgress
                  color="primary"
                  size={16}
                  sx={{ mt: '4px', ml: '4px' }}
                />
              ) : (
                getNotificationIcon()
              )}
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
