import {
  useAppInsightsContext,
  AppInsightsErrorBoundary as AiErrorBoundary
} from '@microsoft/applicationinsights-react-js';
import { FC } from 'react';

export const AppInsightsErrorBoundary: FC = ({ children }) => {
  const appInsights = useAppInsightsContext();

  return (
    <AiErrorBoundary
      onError={() => <h1>Something went wrong</h1>}
      appInsights={appInsights}
    >
      <>{children}</>
    </AiErrorBoundary>
  );
};
