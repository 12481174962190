import {
  AppBar,
  Box,
  Button,
  debounce,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { flatten, get, sortBy } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useEnv, useSessionContext } from 'src/common';
import { useSignalR } from 'src/hooks/use-signal-r/useSignalR';
import { includesIgnoreCase } from 'src/lib';
import { useLotImageService, useProductService } from 'src/services';
import { MicroMarketGrouping } from 'src/types/models/MicroMarketGrouping.type';
import { Product } from 'src/types/models/Product.type';
import { LotImageDialog } from './LotImageDialog';
import { ProductListItem } from './ProductListItem';

interface AddProductsContainerProps {
  isShortScreen: boolean;
}

export const AddProductsContainer = ({
  isShortScreen
}: AddProductsContainerProps) => {
  const [searchProducts, setSearchProducts] = useState<Product[]>([]);
  const { sessionId, locationId, location } = useSessionContext();
  const [tableFilters] = useState<Record<string, string>>({
    locationId: locationId.toString(),
    sessionId
  });
  const {
    featureFlags: { lotUploads }
  } = useEnv();
  const [search, setSearch] = useState('');
  const { getGroupedProductsList } = useProductService();
  const { getLotImageMetadata } = useLotImageService();

  const [tabIndex, setTabIndex] = useState(0);
  const [microMarketGroupings, setMicroMarketGroupings] = useState<
    MicroMarketGrouping[]
  >([]);
  const [lotImageLookup, setLotImageLookup] = useState<Record<string, number>>(
    {}
  );
  const [imageOpen, setImageOpen] = useState(false);
  const [lotImageId, setLotImageId] = useState(0);
  const [selectedLot, setSelectedLot] = useState('');

  const { notification } = useSignalR(location);

  const tabIndexCss = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    (async () => {
      const groupedProductsResponse = await getGroupedProductsList({
        filters: tableFilters
      });

      setSearchProducts(
        flatten(groupedProductsResponse.map(({ products }) => products))
      );

      groupedProductsResponse.push({
        name: 'Search',
        tabIndex: groupedProductsResponse.length + 1,
        products: []
      });

      setMicroMarketGroupings(sortBy(groupedProductsResponse, 'tabIndex'));
    })();
  }, [tableFilters, notification, getGroupedProductsList]);

  useEffect(() => {
    if (lotUploads) {
      (async () => {
        const lotImageMetadata = await getLotImageMetadata(sessionId);

        const lotImageLookup = lotImageMetadata.reduce((lookup, image) => {
          return {
            ...lookup,
            [image.lotNumber]: image.lotImageId
          };
        }, {});

        setLotImageLookup(lotImageLookup);
      })();
    }
  }, [getLotImageMetadata, sessionId, lotUploads]);

  const updateSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearch(event.target.value);

    if (searchText === '') {
      setTabIndex(0);
      return;
    }

    const searchedProducts: Product[] = searchProducts.filter(
      (product) =>
        includesIgnoreCase(product.productName, searchText) ||
        includesIgnoreCase(product.lotNumber, searchText)
    );

    setMicroMarketGroupings(
      microMarketGroupings.map((group) =>
        group.name !== 'Search'
          ? group
          : {
              ...group,
              products: searchedProducts
            }
      )
    );

    setTabIndex(microMarketGroupings.length - 1);
  }, 300);

  const products = useMemo(() => {
    if (!microMarketGroupings || microMarketGroupings.length === 0) {
      return [];
    }

    return get(microMarketGroupings, [tabIndex, 'products'], []);
  }, [microMarketGroupings, tabIndex]);

  const handleImageClickOpen = (imageId: number, lotNumber: string) => {
    setImageOpen(true);
    setSelectedLot(lotNumber);
    setLotImageId(imageId);
  };
  const handleImageClickClose = () => setImageOpen(false);

  const getViewImageButton = (lotNumber: string) => {
    if (tabIndex !== 0 || !lotUploads) {
      return;
    }

    const lotImageId = get(lotImageLookup, lotNumber);

    if (!lotImageId) {
      return;
    }

    return (
      <Button
        onClick={() => {
          handleImageClickOpen(lotImageId, lotNumber);
        }}
        sx={{ marginRight: 2 }}
      >
        View Image
      </Button>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Grid container paddingBottom={isShortScreen ? 0 : 2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            sx={{ width: '100%' }}
            label="Search"
            id="product-search"
            size={isShortScreen ? 'small' : 'medium'}
            onChange={updateSearch}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: '100%' }}>
        <AppBar
          position="static"
          style={{
            background: 'inherit',
            color: 'black',
            boxShadow: 'none',
            borderBottom: '2px solid #dedede'
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                marginTop: isShortScreen ? 1 : 2,
                height: '3px'
              }
            }}
            variant="fullWidth"
          >
            {microMarketGroupings.map((mm, index) => {
              if (mm.name === 'Search' && search === '') {
                return null;
              }
              return (
                <Tab
                  key={mm?.tabIndex}
                  style={{ fontWeight: 600 }}
                  label={mm?.name}
                  {...tabIndexCss(index)}
                />
              );
            })}
          </Tabs>
        </AppBar>
      </Box>
      <Box
        sx={{
          height: '100%',
          overflow: 'auto'
        }}
      >
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {products.length > 0 ? (
            products.map((product) => (
              <ProductListItem
                key={product.productId}
                product={product}
                compact={isShortScreen}
                viewImageButton={getViewImageButton(product.lotNumber)}
              />
            ))
          ) : (
            <Box sx={{ paddingTop: 10, textAlign: 'center' }}>
              <Typography variant="subtitle1" component="h6" fontWeight={600}>
                There are no products to display for this micro market
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <LotImageDialog
        lotNumber={selectedLot}
        open={imageOpen}
        handleClickClose={handleImageClickClose}
        lotImageId={lotImageId}
      />
    </Box>
  );
};
