import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  Typography
} from '@mui/material';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import ReplyIcon from '@mui/icons-material/Reply';
import Avatar from '@mui/material/Avatar';
import { ItemType } from 'src/types';

interface ProductBoxProps {
  productName: string;
  lotNumber: string;
  itemType: ItemType;
  hideStatus?: boolean;
  listItemProps?: ListItemProps;
}

export const ProductListItem = ({
  productName,
  lotNumber,
  itemType,
  hideStatus,
  listItemProps
}: ProductBoxProps) => {
  const renderAvatar = () => {
    if (itemType === ItemType.RequestItem) {
      return (
        <Avatar aria-label="comment" style={{ backgroundColor: '#DFDFDF' }}>
          <QuestionMarkRoundedIcon style={{ color: '#FA8918' }} />
        </Avatar>
      );
    } else if (itemType === ItemType.ReturnItem) {
      return (
        <Avatar aria-label="comment" style={{ backgroundColor: '#DFDFDF' }}>
          <ReplyIcon style={{ color: '#95BF4D' }} />
        </Avatar>
      );
    } else {
      return null;
    }
  };

  const renderAction = () => {
    if (itemType === ItemType.RequestItem) {
      return (
        <Typography
          variant="subtitle2"
          style={{ color: '#FA8918' }}
          component="h1"
          fontWeight={500}
        >
          Request to view
        </Typography>
      );
    } else if (itemType === ItemType.ReturnItem) {
      return (
        <Typography
          variant="subtitle2"
          style={{ color: '#95BF4D' }}
          component="h1"
          fontWeight={500}
        >
          Returning product
        </Typography>
      );
    } else {
      return null;
    }
  };

  return (
    <ListItem {...listItemProps}>
      <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
      <ListItemText>
        <Box>
          <Typography variant="subtitle2" component="h1" fontWeight={500}>
            {productName}
          </Typography>
          <Typography variant="caption" component="h1" color={'#8a8a8a'}>
            Lot {lotNumber}
          </Typography>
          {!hideStatus && renderAction()}
        </Box>
      </ListItemText>
    </ListItem>
  );
};
