import { IDecodedJwt } from 'src/types/interface/IDecodedJwt';
import jwtDecode from 'jwt-decode';

export const validatePermissions = (
  setAuthorised: (authorized: boolean) => void,
  permission: string,
  token: string
) => {
  if (permission === undefined) {
    throw new Error('Permission was undefined');
  }
  const decoded = jwtDecode<IDecodedJwt>(token);
  const roles =
    decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  if (!roles) {
    setAuthorised(false);
    return;
  }

  const hasPermission = roles.includes(permission);
  setAuthorised(hasPermission);
};
