import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { ProductNotificationResponse } from 'src/types';
import { EnumerableResponse } from 'src/types/api/EnumerableResponse.type';

export const useProductNotificationService = () => {
  const { auctionsApi } = useServiceProvider();

  const createProductNotification = useCallback(
    async (sessionId: string, productId: number): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.post(
        '/ProductNotifications',
        {
          sessionId,
          productId
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const deleteProductNotification = useCallback(
    async (sessionId: string, productId: number): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.delete(
        '/ProductNotifications',
        {
          params: { sessionId, productId }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const getProductNotificationsList = useCallback(
    async (
      sessionId: string
    ): Promise<EnumerableResponse<ProductNotificationResponse>> => {
      const response: AxiosResponse<
        EnumerableResponse<ProductNotificationResponse>
      > = await auctionsApi.get('/ProductNotifications', {
        params: { sessionId }
      });
      return response.data;
    },
    [auctionsApi]
  );

  return {
    createProductNotification,
    deleteProductNotification,
    getProductNotificationsList
  };
};
