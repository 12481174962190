import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { HubConnectionState } from '@microsoft/signalr';
import { Button } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSessionContext, useEnv, useServiceProvider } from 'src/common';
import { v4 as uuid } from 'uuid';

export const useSignalR = (locationName: string) => {
  const [notification, setNotification] = useState(uuid());
  const [subscription, setSubscription] = useState<string | null>(null);
  const [acceptNotification, setAcceptNotification] = useState<string | null>(
    null
  );
  const [declineNotification, setDeclineNotification] = useState<string | null>(
    null
  );

  const { auctionsApiUrl, viewingsHubPath } = useEnv();

  const { sessionId } = useSessionContext();

  const { hubConnection, stopListening } = useServiceProvider();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    const action = (snackbarId: SnackbarKey) => (
      <>
        <Button
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          Dismiss
        </Button>
      </>
    );
    const createConnection = async () => {
      if (locationName !== '') {
        const waitUntilConnected = () => {
          return new Promise((resolve, reject) => {
            const msMax = 5000;
            const msInc = 50;

            let ms = 0;

            const idInterval = setInterval(() => {
              if (hubConnection.state === HubConnectionState.Connected) {
                clearInterval(idInterval);
                resolve(hubConnection);
              }

              ms += msInc;

              if (ms >= msMax) {
                clearInterval(idInterval);
                reject(hubConnection);
              }
            }, msInc);
          });
        };

        try {
          await waitUntilConnected();
          await hubConnection.invoke('JoinGroup', locationName);
          await hubConnection.invoke('JoinGroup', sessionId);

          hubConnection.on('ProductUpdate', () => {
            setNotification(uuid());
          });

          hubConnection.on('ProductNotification', () => {
            setSubscription(uuid());
          });

          hubConnection.on('RequestDeclineNotification', () => {
            setDeclineNotification(uuid());
          });

          hubConnection.on('RequestAcceptNotification', () => {
            setAcceptNotification(uuid());
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          appInsights.trackException({
            exception: err as Error,
            severityLevel: SeverityLevel.Error,
            properties: {
              ...(err as Error)
            }
          });
          enqueueSnackbar(
            'Live updates have been interrupted, please refresh your page',
            {
              action,
              variant: 'warning',
              persist: true,
              preventDuplicate: true
            }
          );
        }
      }
    };

    // eslint-disable-next-line no-console
    createConnection().catch((err) => console.error(err));

    return () => {
      (async () => {
        await stopListening(locationName, sessionId);
      })();
    };
  }, [
    auctionsApiUrl,
    hubConnection,
    locationName,
    viewingsHubPath,
    enqueueSnackbar,
    sessionId,
    stopListening,
    appInsights,
    closeSnackbar
  ]);

  return {
    notification,
    subscription,
    declineNotification,
    acceptNotification
  };
};
