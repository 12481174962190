import { useMemo, useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import { clamp } from 'lodash';

export const useMinuteCountdown = (startTime: Date, finishTime: Date) => {
  const totalTime = differenceInMinutes(finishTime, startTime, {
    roundingMethod: 'ceil'
  });
  const [remainingTime, setRemainingTime] = useState(
    clamp(differenceInMinutes(finishTime, new Date()), 0, totalTime)
  );

  const progress = useMemo(
    () =>
      totalTime === 0
        ? 0
        : clamp(Math.round((remainingTime / totalTime) * 100), 0, 100),
    [remainingTime, totalTime]
  );

  const reduceTime = (decrement = 1) => {
    setRemainingTime((currentTime) =>
      clamp(currentTime - decrement, 0, totalTime)
    );
  };

  return { remainingTime, reduceTime, progress };
};
