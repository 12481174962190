import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { ProductRatingSubmit } from 'src/types';

export const useProductRatingsService = () => {
  const { auctionsApi } = useServiceProvider();

  const createProductRatings = useCallback(
    async (ProductRatingSubmit: ProductRatingSubmit): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.post(
        '/ProductRatings/Complete',
        ProductRatingSubmit,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  return {
    createProductRatings
  };
};
