export const tokenUserPermissions = {
  CUSTOMER: {
    GET: 'customer:g',
    ACCOUNT_OWNER_GET: 'customerAccountOwner:g',
    ACCOUNT_OFFICE_GET: 'customerAccountOffices:g',
    POST: 'customer:p',
    FIND: 'customer:f',
    PUT: 'customer:u'
  },
  LOCATION: {
    FIND_BY_ROOM: 'locationByRoom:f',
    GET: 'location:g',
    POST: 'location:p',
    FIND: 'location:f'
  },
  MICRO_MARKET: {
    GET: 'microMarket:g'
  },
  PRODUCT_ITINERARY: {
    GET: 'productItinerary:g',
    POST: 'productItinerary:p',
    LOCATION_AVAILABLE_DATES: 'locationAvailableDates:f'
  },
  PRODUCT_NOTIFICATION: {
    POST: 'productNotification:p',
    DELETE: 'productNotification:d',
    FIND: 'productNotification:f'
  },
  PRODUCT: {
    GET: 'product:g',
    POST: 'product:p',
    GROUPED_PRODUCTS_GET: 'groupedProducts:g',
    UPDATE_AVAILABILITY: 'productAvailability:u',
    PRODUCT_NAMES_GET: 'productNames:g'
  },
  PRODUCT_RATING: {
    POST: 'productRating:p',
    Find: 'productRatingExport:f'
  },
  PRODUCT_VIEWING: {
    FIND: 'productViewing:f',
    REQUEST_FIND: 'productRequest:f',
    RETURN_FIND: 'productReturn:f',
    POST: 'productViewing:p',
    PUT: 'productViewing:u',
    DELETE: 'productViewing:d',
    SUBMIT: 'submitProductViewing:p',
    IN_FLIGHT_FIND: 'inflightProducts:f',
    END_SESSION: 'endSession:u',
    VIEWINGS_EXPORT: 'viewingsExport:g'
  },
  SALES_CYCLE: {
    GET: 'salesCycle:g',
    POST: 'salesCycle:p',
    PUT: 'salesCycle:u',
    FIND: 'salesCycle:f'
  },
  SESSION: {
    GET_ACTIVE: 'activeSession:g',
    POST: 'session:p',
    PUT: 'session:u',
    VALIDATE_CODE: 'sessionConfirm:f',
    FIND_BY_ID: 'sessionById:f',
    FIND_BY_BOOKING_ID: 'sessionByBookingId:f',
    MAX_LOT_UPDATE: 'sessionMaxLot:u'
  },
  DASHBOARD: {
    GET: 'dashboard:g',
    STATUS_UPDATE: 'sessionStatus:u',
    COMPLETE_REQUEST: 'completeRequest:p',
    DECLINE_REQUEST: 'declineRequest:p',
    OVERVIEW_FIND: 'locationOverview:f'
  },
  BOOKING: {
    FIND: 'bookingByLocation:f',
    POST: 'booking:p',
    PUT: 'booking:u',
    DELETE: 'booking:d',
    BOOKED_ROOM_FIND: 'bookedRooms:f',
    BOOKINGS_EXPORT: 'bookingExport:g',
    BOOKING_CUSTOMER_GET: 'bookingCustomer:g'
  }
};
