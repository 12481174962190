import './fonts/FuturaStdMedium.ttf';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Outlet } from 'react-router-dom';
import {
  ErrorBoundary,
  RoutesContainer,
  EnvProvider,
  ServiceProvider,
  SessionProvider
} from './common';
import { stylesOverride } from './styles-override';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ProductsContextProvider } from './common/products-context-provider';
import { SnackbarProvider } from 'notistack';
import { AuthenticationContextProvider } from './common/authentication-context-provider/AuthenticationContextProvider';
import { NotificationDrawerContextProvider } from './common/notification-drawer-context-provider';
import { ApplicationInsightsContext } from './common/application-insights-context-provider/ApplicationInsightsContextProvider';
import { AppInsightsErrorBoundary } from './common/error-boundary/AppInsightsErrorBoundary';

const App = () => {
  const styles = {
    content: {
      flexGrow: 1
    }
  } as const;

  return (
    <ErrorBoundary>
      <EnvProvider
        config={{
          dateFormatString: 'yyyy-MM-dd',
          dateTimeFormatString: 'yyyy-MM-dd HH:mm'
        }}
      >
        <ApplicationInsightsContext>
          <AppInsightsErrorBoundary>
            <AuthenticationContextProvider>
              <ServiceProvider>
                <SessionProvider>
                  <ThemeProvider theme={createTheme(stylesOverride)}>
                    <SnackbarProvider
                      maxSnack={3}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      autoHideDuration={3000}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ProductsContextProvider>
                          <NotificationDrawerContextProvider>
                            <Router>
                              <Box sx={{ display: 'flex' }}>
                                <CssBaseline />
                                <Box component="main" sx={styles.content}>
                                  <RoutesContainer />
                                  <Outlet />
                                </Box>
                              </Box>
                            </Router>
                          </NotificationDrawerContextProvider>
                        </ProductsContextProvider>
                      </LocalizationProvider>
                    </SnackbarProvider>
                  </ThemeProvider>
                </SessionProvider>
              </ServiceProvider>
            </AuthenticationContextProvider>
          </AppInsightsErrorBoundary>
        </ApplicationInsightsContext>
      </EnvProvider>
    </ErrorBoundary>
  );
};

export default App;
