import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { TableContextProvider, Table } from 'src/common/table';
import { TableRowData } from 'src/types';
import { FetchRows } from 'src/types/FetchRows.type';

interface IProductViewingHistoryArea {
  fetchRows?: FetchRows<TableRowData>;
  currentSalesCycle: string;
}

const HEADERS = [
  {
    id: 'sessionDate',
    label: 'Date'
  },
  {
    id: 'lotNo',
    label: 'Lot No'
  },
  {
    id: 'productName',
    label: 'Product Name'
  },
  {
    id: 'totalViewingDuration',
    label: 'Total Viewing Duration'
  }
];

export const ProductViewingHistoryArea = ({
  fetchRows,
  currentSalesCycle
}: IProductViewingHistoryArea) => {
  return (
    <TableContextProvider disableSort>
      <Paper sx={{ padding: 2, mt: 2 }}>
        <Box
          sx={{
            height: '100%'
          }}
          pt={0}
        >
          <Box my={3}>
            <Typography
              sx={{
                letterSpacing: 1,
                fontSize: '1rem',
                fontWeight: 'bold'
              }}
            >
              HISTORY OF VIEWED LOTS
            </Typography>
          </Box>
          <Box my={3}>
            <Grid container spacing={2} display="flex" alignItems="center">
              <Grid item>
                <Typography variant="body1">Sales Cycle:</Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="salesCycle"
                  value={currentSalesCycle}
                  size="small"
                  sx={{ width: '6rem' }}
                  focused
                  inputProps={{
                    sx: {
                      caretColor: 'transparent'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Table headers={HEADERS} fetchRows={fetchRows} />
        </Box>
      </Paper>
    </TableContextProvider>
  );
};
