import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Divider
} from '@mui/material';
import { useState } from 'react';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { ProductNotificationResponse, ProductNotification } from 'src/types';

type ProductNotificationListItemProps = {
  productNotification: ProductNotificationResponse;
  handleUnsubscribeFromNotification: (
    productNotificationPassed: ProductNotification
  ) => Promise<void>;
};

export const ProductNotificationListItem = ({
  productNotification,
  handleUnsubscribeFromNotification
}: ProductNotificationListItemProps) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Box
        sx={{
          py: 1,
          display: 'flex'
        }}
      >
        <Box>
          <Typography
            variant="body2"
            style={{
              color: 'black',
              paddingLeft: 12,
              paddingRight: 12
            }}
          >
            {productNotification.productName}
          </Typography>
          <Typography
            variant="caption"
            style={{
              color: 'black',
              paddingLeft: 12,
              paddingRight: 12
            }}
          >
            LotNo: {productNotification.lotNumber}
          </Typography>
        </Box>
        <IconButton
          style={{
            marginLeft: 'auto',
            marginRight: 20
          }}
          onClick={async () => {
            setIsLoading(true);
            await handleUnsubscribeFromNotification(productNotification);
            setIsLoading(false);
          }}
        >
          {isLoading ? (
            <CircularProgress color="primary" size={16} />
          ) : (
            <NotificationsActiveIcon
              sx={{ color: (theme) => theme.palette.secondary.main }}
            />
          )}
        </IconButton>
      </Box>
      <Divider />
    </>
  );
};
