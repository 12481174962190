import { Button } from '@mui/material';

export const NotAuthorized = () => {
  return (
    <>
      <h1>Not authorized</h1>
      <Button variant="text" href="/home" sx={{ fontWeight: 'bold' }}>
        Return home
      </Button>
    </>
  );
};
