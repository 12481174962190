import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  BottomNavigation,
  Grid,
  SxProps,
  Theme,
  Container,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProductsContext, useSessionContext } from 'src/common';
import { PendingWrapper } from 'src/modules/viewings/components/PendingWrapper';

interface IRequestNavigationBar {
  steps: string[];
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  clearCustomerData: (releaseHold?: boolean) => Promise<void>;
  isLoading: boolean;
  compact: boolean;
}

export const RequestNavigationBar = ({
  steps,
  activeStep,
  handleNext,
  handleBack,
  clearCustomerData,
  isLoading,
  compact
}: IRequestNavigationBar) => {
  const styles: Record<string, SxProps<Theme>> = {
    button: {
      color: '#f5f5f5',
      fontSize: '17px',
      fontWeight: 'light',
      borderRadius: 0,
      maxWidth: '120px',
      maxHeight: '50px',
      minWidth: '120px',
      minHeight: '50px'
    },
    nextButton: {
      color: '#f5f5f5',
      fontSize: '17px',
      fontWeight: 'light',
      borderRadius: 0,
      maxWidth: '120px',
      maxHeight: '50px',
      minWidth: '120px',
      minHeight: '50px',
      borderColor: '#f5f5f5',
      ':hover': { borderColor: '#f5f5f5' }
    }
  };

  const { currentProductSlots, onHoldProducts, returningProducts } =
    useProductsContext();
  const { maxProducts } = useSessionContext();
  const navigate = useNavigate();

  const handleCancel = async () => {
    await clearCustomerData(true);
    navigate('/viewings', { replace: true });
  };

  return (
    <BottomNavigation
      sx={{ backgroundColor: '#12233A', height: compact ? '80px' : '100px' }}
    >
      <Container>
        <Box pl={8} pr={10} sx={{ height: '100%' }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: '100%' }}
          >
            <Grid item xs={1}>
              <Button
                id="navigation-cancel"
                onClick={handleCancel}
                size="large"
                sx={styles.button}
              >
                Cancel
              </Button>
            </Grid>
            <PendingWrapper>
              <>
                <Box>
                  <Typography
                    id="available-product-slots"
                    sx={{
                      color: (theme) => theme.palette.grey[400],
                      fontSize: '20px',
                      fontWeight: 'light'
                    }}
                    variant="h5"
                  >
                    Available product slots: {currentProductSlots}/{maxProducts}
                  </Typography>
                </Box>

                <Grid item container xs={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      onClick={handleBack}
                      sx={styles.button}
                      disabled={activeStep === 0}
                      id="back-button"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <LoadingButton
                      variant="outlined"
                      size="large"
                      onClick={handleNext}
                      sx={styles.nextButton}
                      id="submit-button"
                      loading={isLoading}
                      disabled={
                        activeStep === steps.length - 1 &&
                        onHoldProducts.length + returningProducts.length < 1
                      }
                    >
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </>
            </PendingWrapper>
          </Grid>
        </Box>
      </Container>
    </BottomNavigation>
  );
};
