import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useProductsContext,
  useServiceProvider,
  useSessionContext
} from 'src/common';
import { useProductViewingsService } from 'src/services';

interface IViewingsContainerDialog {
  open: boolean;
  handleClickClose: () => void;
}

export const ViewingsContainerEndViewingDialog = ({
  open,
  handleClickClose
}: IViewingsContainerDialog) => {
  const { currentProducts, hasRequestedProducts } = useProductsContext();
  const { clearLocalStorage, sessionId, location } = useSessionContext();
  const { stopListening } = useServiceProvider();

  const { updateProductViewingForEnd } = useProductViewingsService();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const getCustomerMessage = () => {
    if (currentProducts.length === 0 && hasRequestedProducts.length === 0) {
      return 'Please note that a end session request will be sent to the KAMs. Please wait for a KAM to complete the request.';
    }
    if (currentProducts.length > 0 && hasRequestedProducts.length === 0) {
      return 'Please note that a end session request will be sent to the KAMs, and all products currently being viewed will be returned. Please wait for a KAM to complete the request.';
    }
    if (hasRequestedProducts.length > 0 && currentProducts.length >= 0) {
      return 'Please note that you have products currently being requested. \n Either ask the KAM to decline the request or wait for the products to be delivered before ending the request.';
    }
  };

  const endSessionWithOrWithoutProducts = async () => {
    setIsLoading(true);

    if (
      currentProducts.length > 0 ||
      (currentProducts.length === 0 && hasRequestedProducts.length === 0)
    ) {
      const productsViewingRequest = {
        sessionId,
        locationName: location
      };

      const response = (await updateProductViewingForEnd(
        productsViewingRequest
      )) as AxiosResponse;

      if (response.status < 300 && response.status >= 200) {
        enqueueSnackbar('Request submitted successfully', {
          variant: 'success'
        });
        navigate('/end', { replace: true });
        clearLocalStorage();
      } else if (response.status >= 400 && response.status < 500) {
        enqueueSnackbar(response.data as string, {
          variant: 'warning'
        });
      } else {
        enqueueSnackbar('An error occurred while submitting your request', {
          variant: 'error'
        });
      }
    }

    await stopListening(location, sessionId);
    setIsLoading(false);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClickClose}
        PaperProps={{ sx: { minWidth: '600px', maxWidth: '600px' } }}
      >
        <Typography variant="h5" color="primary" sx={{ pt: 3, pl: 3, pb: 2 }}>
          Are you sure you wish to end your viewing ?
        </Typography>

        <DialogContent>
          <Box>
            <Typography variant="body1" color="primary">
              {getCustomerMessage()}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ width: '30%', fontSize: '1rem' }}
            onClick={handleClickClose}
          >
            Cancel
          </Button>
          {hasRequestedProducts.length > 0 ? (
            ''
          ) : (
            <LoadingButton
              variant="contained"
              sx={{ width: '30%', fontSize: '1rem' }}
              onClick={endSessionWithOrWithoutProducts}
              loading={isLoading}
              loadingIndicator={<CircularProgress color="primary" size={16} />}
            >
              End
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};
