import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useProductsContext } from 'src/common';
import { Product } from 'src/types';
import { ProductViewingStatus } from 'src/types/enums/ProductViewingStatus';

interface ProductBoxProps {
  product: Product;
}
export const ReturnProductListItem = ({ product }: ProductBoxProps) => {
  const {
    addReturningProduct,
    removeReturningProduct,
    isInRequestList,
    currentProductSlots
  } = useProductsContext();

  const shouldBeCheckable = useCallback((productViewingStatus?: number) => {
    if (
      productViewingStatus &&
      productViewingStatus === ProductViewingStatus.Viewing
    ) {
      return true;
    }
    return false;
  }, []);

  const shouldDisallowCheckbox = () => {
    return currentProductSlots === 0 && checked;
  };

  const isChecked = isInRequestList(product.productId);

  const [checked, setChecked] = useState(isChecked);

  const handleCheckboxChange = (newChecked: boolean) => {
    if (!product) {
      return;
    }
    if (newChecked) {
      addReturningProduct(product);
    } else {
      removeReturningProduct(product.productId);
    }
    setChecked(newChecked);
  };

  if (!product) {
    return null;
  }

  const getStatusToDisplay = () => {
    if (!product.productViewingStatusId) {
      return 'Unknown';
    }
    if (product.productViewingStatusId === ProductViewingStatus.Viewing) {
      return 'Viewing';
    }
    if (product.productViewingStatusId === ProductViewingStatus.Returning) {
      return 'Returning';
    }
    return 'Error';
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={2}>
          <Box sx={{ pt: 1.5 }}>
            <Checkbox
              style={{ transform: 'scale(1.5)' }}
              checked={checked}
              indeterminate={!shouldBeCheckable(product.productViewingStatusId)}
              disabled={
                !shouldBeCheckable(product.productViewingStatusId) ||
                shouldDisallowCheckbox()
              }
              onChange={(_event, checked) => handleCheckboxChange(checked)}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box>
            <Typography variant="subtitle2" component="h1" fontWeight={500}>
              {product.productName}
            </Typography>
            <Typography variant="caption" component="h1" color={'#8a8a8a'}>
              Lot {product.lotNumber}
            </Typography>
            <Typography variant="subtitle2" component="h1" fontWeight={500}>
              Status: {getStatusToDisplay()}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
