import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, List, Typography } from '@mui/material';
import { useEffect } from 'react';
import { ProductListItem, useSessionContext } from 'src/common';
import { useProductsContext } from 'src/common/products-context-provider';
import { useProductViewingsService } from 'src/services';
import { ItemType } from 'src/types';

interface SubmitRequestsContainerProps {
  isShortScreen: boolean;
}

export const SubmitRequestsContainer = ({
  isShortScreen
}: SubmitRequestsContainerProps) => {
  const {
    returningProducts,
    selectedProducts,
    removeProductFromSelection,
    onHoldProducts,
    setOnHoldProducts,
    removeOnHoldProduct,
    removeReturningProduct,
    currentProductSlots
  } = useProductsContext();
  const { sessionId, location } = useSessionContext();

  const { createProductViewings, deleteProductViewing } =
    useProductViewingsService();

  const heightToSubtract = isShortScreen ? '210px' : '320px';

  useEffect(() => {
    (async () => {
      const productsOnHold = await createProductViewings({
        productViewings: selectedProducts.map((product) => ({
          ...product,
          sessionId: sessionId,
          productItemType: ItemType.RequestItem
        })),
        sessionId,
        locationName: location
      });

      const unavailableProducts = selectedProducts.filter(
        (product) =>
          !productsOnHold.some((hold) => hold.productId === product.productId)
      );

      unavailableProducts.forEach((product) => {
        removeProductFromSelection(product.productId, true);
      });

      const products = productsOnHold.map((product) => ({
        ...product,
        productType: ItemType.RequestItem
      }));

      setOnHoldProducts(products);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveSelectedProduct = async (productId: number) => {
    removeProductFromSelection(productId);
    removeOnHoldProduct(productId);
    await deleteProductViewing(productId, location);
  };

  if (onHoldProducts.length === 0 && returningProducts.length === 0) {
    return (
      <Box sx={{ paddingTop: 20, textAlign: 'center' }}>
        <Typography variant="h6" fontWeight={800}>
          You have not selected any products in this request, please navigate to
          Step 1 or Step 2 and select products.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${heightToSubtract})`,
        maxHeight: '100%',
        position: 'relative',
        overflow: 'auto',
        backgroundColor: 'background.paper'
      }}
    >
      <List>
        {returningProducts.map((product) => (
          <>
            <ProductListItem
              productName={product.productName}
              lotNumber={product.lotNumber}
              itemType={ItemType.ReturnItem}
              key={product.productId}
              listItemProps={{
                secondaryAction:
                  currentProductSlots > 0 ? (
                    <IconButton
                      onClick={() => removeReturningProduct(product.productId)}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : null
              }}
            />
            <Divider
              sx={{
                marginY: isShortScreen ? 0.5 : 2
              }}
            />
          </>
        ))}
      </List>
      <List>
        {onHoldProducts.map((product, index) => (
          <>
            {index > 0 && (
              <Divider
                sx={{
                  marginY: isShortScreen ? 0.5 : 2
                }}
              />
            )}
            <ProductListItem
              productName={product.productName}
              lotNumber={product.lotNo}
              itemType={ItemType.RequestItem}
              key={product.productId}
              listItemProps={{
                secondaryAction: (
                  <IconButton
                    onClick={() =>
                      handleRemoveSelectedProduct(product.productId)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }}
            />
          </>
        ))}
      </List>
    </Box>
  );
};
