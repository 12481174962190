import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import BallotIcon from '@mui/icons-material/Ballot';
import {
  ItemType,
  ProductPendingRequest,
  ProductViewingStatusStrings
} from 'src/types';
import { useMemo } from 'react';
import { ProductListItem } from 'src/common';

interface IPendingRequests {
  products: ProductPendingRequest[];
}

export const PendingRequests = ({ products }: IPendingRequests) => {
  const requestingProducts = useMemo(
    () =>
      products.filter(
        (product) =>
          product.productViewingStatus ===
          ProductViewingStatusStrings.Requesting
      ),
    [products]
  );
  const returningProducts = useMemo(
    () =>
      products.filter(
        (product) =>
          product.productViewingStatus === ProductViewingStatusStrings.Returning
      ),
    [products]
  );

  return (
    <Box sx={{ height: '100%' }} id="pending-requests-section">
      <Paper sx={{ height: '100%', backgroundColor: '#f7f7f7' }} square>
        <Box p={4} display="flex" alignItems="center">
          <Avatar sx={{ marginRight: 2 }}>
            <BallotIcon />
          </Avatar>
          <Typography variant="h5">Request</Typography>
        </Box>
        {requestingProducts.length > 0 && (
          <List>
            <ListItem>
              <ListItemText>Requesting</ListItemText>
            </ListItem>
            <Divider />
            {requestingProducts.map(({ productName, lotNumber }, index) => (
              <ProductListItem
                productName={productName}
                lotNumber={lotNumber}
                itemType={ItemType.RequestItem}
                hideStatus={true}
                key={index}
              />
            ))}
          </List>
        )}
        {returningProducts.length > 0 && (
          <List>
            <ListItem>
              <ListItemText>Returning</ListItemText>
            </ListItem>
            <Divider />
            {returningProducts.map(({ productName, lotNumber }, index) => (
              <ProductListItem
                productName={productName}
                lotNumber={lotNumber}
                itemType={ItemType.ReturnItem}
                hideStatus={true}
                key={index}
              />
            ))}
          </List>
        )}
      </Paper>
    </Box>
  );
};
