import { AxiosResponse } from 'axios';
import { Request } from '../types';
import { EnumerableResponse } from '../types/api/EnumerableResponse.type';
import { MicroMarketGrouping } from '../types/models/MicroMarketGrouping.type';
import { get } from 'lodash';
import { useServiceProvider } from 'src/common';
import { useCallback } from 'react';

export const useProductService = () => {
  const { auctionsApi } = useServiceProvider();

  const getGroupedProductsList = useCallback(
    async ({
      search,
      filters
    }: Request): Promise<EnumerableResponse<MicroMarketGrouping>> => {
      const response: AxiosResponse<EnumerableResponse<MicroMarketGrouping>> =
        await auctionsApi.get('/Products/GroupedProducts', {
          params: {
            search,
            locationId: get(filters, 'locationId', -1),
            sessionId: get(filters, 'sessionId', -1)
          }
        });
      return response.data;
    },
    [auctionsApi]
  );

  return { getGroupedProductsList };
};
