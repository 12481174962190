import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoadingIndicator } from '..';
import { QueryParamProvider } from 'use-query-params';
import { RouteAdaptor } from './RouteAdaptor';
import { SplashScreenContainer } from 'src/modules/splash-screen';
import { ViewingsContainer } from 'src/modules/viewings';
import { RequestFlowContainer } from 'src/modules/request-flow';
import { LoginScreenContainer } from 'src/modules/login-screen';
import EndViewingContainer from 'src/modules/end-viewing-screen';
import { AzureLoginContainer } from 'src/modules/azure-login/AzureLoginContainer';
import { tokenUserPermissions } from 'src/lib/constants/token-user-permissions';
import NotAuthorized from 'src/modules/not-authorized';
import RequireAuth from '../require-auth';

export const RoutesContainer = () => {
  return (
    <>
      <Suspense fallback={<LoadingIndicator text="Loading Page..." />}>
        <QueryParamProvider ReactRouterRoute={RouteAdaptor}>
          <Routes>
            <Route path="/" element={<AzureLoginContainer />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route
              path="/home"
              element={
                <RequireAuth
                  permission={tokenUserPermissions.SESSION.VALIDATE_CODE}
                  checkSession={false}
                >
                  <SplashScreenContainer />
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={
                <RequireAuth
                  permission={tokenUserPermissions.SESSION.VALIDATE_CODE}
                  checkSession={false}
                >
                  <LoginScreenContainer />
                </RequireAuth>
              }
            />
            <Route
              path="/request-flow"
              element={
                <RequireAuth
                  permission={tokenUserPermissions.PRODUCT.GROUPED_PRODUCTS_GET}
                >
                  <RequestFlowContainer />
                </RequireAuth>
              }
            />
            <Route
              path="/viewings"
              element={
                <RequireAuth
                  permission={tokenUserPermissions.PRODUCT_VIEWING.FIND}
                >
                  <ViewingsContainer />
                </RequireAuth>
              }
            />
            <Route
              path="/end"
              element={
                <RequireAuth
                  permission={tokenUserPermissions.PRODUCT_VIEWING.END_SESSION}
                  checkSession={false}
                >
                  <EndViewingContainer />
                </RequireAuth>
              }
            />
          </Routes>
        </QueryParamProvider>
      </Suspense>
    </>
  );
};
