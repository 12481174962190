import {
  AppInsightsContext as msAIContext,
  ReactPlugin
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useEnv } from '../env-context-provider/EnvContextProvider';

export const ApplicationInsightsContext: FC = ({ children }) => {
  const { appInsightsConnectionString } = useEnv();
  const reactPlugin = new ReactPlugin();

  if (!isEmpty(appInsightsConnectionString)) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: appInsightsConnectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
      }
    });

    appInsights.loadAppInsights();
  }

  return (
    <msAIContext.Provider value={reactPlugin}>{children}</msAIContext.Provider>
  );
};
