import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticationContext } from 'src/common/authentication-context-provider/AuthenticationContextProvider';

import { isEmpty } from 'lodash';
import { AzureLogin } from './AzureLogin';

export const AzureLoginContainer = () => {
  const { getAccessToken, isLoggedIn, signIn } = useAuthenticationContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = useCallback(
    async (token: string, setLoggedIn: (loggedIn: boolean) => void) => {
      try {
        if (await isLoggedIn(true)) {
          setLoggedIn(true);
          return;
        }
        if (isEmpty(token)) {
          signIn();
        } else {
          getAccessToken(token);
        }
        const log = await isLoggedIn(true);
        setLoggedIn(log);
      } catch (err) {
        setLoggedIn(false);
      }
    },
    [getAccessToken, isLoggedIn, signIn]
  );

  useEffect(() => {
    const token = location.hash.split('&')[0].replace('#id_token=', '');
    (async () => await handleLogin(token, setLoggedIn))();
  }, [handleLogin, location.hash]);

  return (
    <div>
      {!loggedIn && <AzureLogin />}
      {loggedIn && navigate('/home')}
    </div>
  );
};
