import { get, isNil } from 'lodash';
import { Env } from 'src/types';

export const validateEnv = (
  envConfig: Partial<Env>,
  defaultConfig?: Partial<Env>
): Env => {
  envConfig.auctionsApiUrl ||= defaultConfig?.auctionsApiUrl;
  if (!envConfig.auctionsApiUrl) {
    throw new Error('Auctions API Url not defined in ENV config');
  }

  envConfig.bookingsApiUrl ||= defaultConfig?.bookingsApiUrl;
  if (!envConfig.bookingsApiUrl) {
    throw new Error('Bookings API Url not defined in ENV config');
  }

  envConfig.viewingsHubPath ||= defaultConfig?.viewingsHubPath;
  if (!envConfig.viewingsHubPath) {
    throw new Error('Viewings Hub path not defined in ENV config');
  }

  envConfig.authenticationApiUrl ||= defaultConfig?.authenticationApiUrl;
  if (!envConfig.authenticationApiUrl) {
    throw new Error('Authentication API Url not defined in ENV config');
  }

  envConfig.azureAuthority ||= defaultConfig?.azureAuthority;
  if (!envConfig.azureAuthority) {
    throw new Error('Azure Authority not defined in ENV config');
  }

  envConfig.azureClientId ||= defaultConfig?.azureClientId;
  if (!envConfig.azureClientId) {
    throw new Error('Azure Client ID not defined in ENV config');
  }

  envConfig.azureRedirectUri ||= defaultConfig?.azureRedirectUri;
  if (!envConfig.azureRedirectUri) {
    throw new Error('Azure Redirect URI not defined in ENV config');
  }

  envConfig.appInsightsConnectionString ||=
    defaultConfig?.appInsightsConnectionString;

  envConfig.jwtScopesKey ||= defaultConfig?.jwtScopesKey ?? '';

  envConfig.dateFormatString ||=
    defaultConfig?.dateFormatString ?? 'yyyy-MM-dd';

  envConfig.dateTimeFormatString ||=
    defaultConfig?.dateTimeFormatString ?? 'yyyy-MM-dd HH:mm';

  envConfig.featureFlags ||= {};

  if (isNil(envConfig.featureFlags.lotUploads)) {
    const defaultValue = get(defaultConfig, 'featureFlags.lotUploads');
    envConfig.featureFlags.lotUploads = !isNil(defaultValue)
      ? defaultValue
      : false;
  }

  return envConfig as Env;
};
