import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionService, useLocationService } from 'src/services';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PinField from './components/PinField';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useSessionContext } from 'src/common';

export const LoginScreenContainer = () => {
  const [showComplete, setShowComplete] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    setSessionId,
    setLocation,
    setMaxProducts,
    setRoomId,
    setLocationId,
    setCustomerId
  } = useSessionContext();
  const { getLocationByRoomId } = useLocationService();
  const { confirmSession } = useSessionService();
  const [loading, setLoading] = useState(false);

  const completed = async (pinValue: string) => {
    try {
      setLoading(true);
      const session = await confirmSession(pinValue);
      const location = await getLocationByRoomId(session.data.roomId);
      setLocation(location.locationName);
      setLocationId(location.locationId);
      setSessionId(session.data.sessionId);
      setMaxProducts(session.data.maxLots);
      setRoomId(session.data.roomId);
      setCustomerId(session.data.customerId);
      setLoading(false);
      setShowComplete(true);
      debounceNavigate();
    } catch (error) {
      const err = error as AxiosError;
      const { response } = err;
      if (response?.status === 400) {
        enqueueSnackbar('Code invalid', {
          variant: 'error'
        });
      }
      setLoading(false);
    }
  };

  const debounceNavigate = debounce(() => {
    navigate('/viewings', { replace: true });
  }, 2000);

  if (showComplete) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Box sx={{ fontWeight: 'bold' }}>
            <Typography variant="h3">Code completed</Typography>
          </Box>
        </Grid>

        <Grid item mt={1}>
          <Typography variant="subtitle1" fontSize={24}>
            Start your viewing session
          </Typography>
        </Grid>

        <Grid item mt={2} mb={2} sx={{ width: '400px', fontSize: 150 }}>
          <CheckCircleIcon fontSize="inherit" />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={4} lg={3}>
          <Box display="flex" flexDirection="column" alignItems="stretch">
            <Typography variant="h3">Enter room code</Typography>
            <Typography variant="subtitle1" fontSize={24}>
              Enter room code to get started with your viewing
            </Typography>

            <Box>
              {loading ? (
                <CircularProgress sx={{ marginTop: 8 }} />
              ) : (
                <PinField completed={completed} />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default LoginScreenContainer;
