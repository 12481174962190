import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { LocationResponse } from 'src/types/models/LocationResponse.type';

export const useLocationService = () => {
  const { bookingsApi } = useServiceProvider();

  const getLocationByRoomId = useCallback(
    async (roomId: number): Promise<LocationResponse> => {
      const response: AxiosResponse<LocationResponse> = await bookingsApi.get(
        `/Locations/room/${roomId}`
      );
      return response.data;
    },
    [bookingsApi]
  );

  return { getLocationByRoomId };
};
