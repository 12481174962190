import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { validatePermissions } from 'src/lib/authentication/validate-permissions';
import { useAuthenticationContext } from '../authentication-context-provider/AuthenticationContextProvider';
import { useSessionContext } from '../session-context-provider';

const checkLoggedInState = async (
  setLoggedIn: (loggedIn: boolean) => void,
  setCheckingLoggedInState: (checkingLoggedInState: boolean) => void,
  setAuthorised: (authorized: boolean) => void,
  isLoggedIn: (refresh: boolean) => Promise<boolean>
) => {
  setCheckingLoggedInState(true);
  try {
    const loggedIn = await isLoggedIn(false);
    setLoggedIn(loggedIn);
  } catch (err) {
    setAuthorised(false);
    setLoggedIn(false);
  } finally {
    setCheckingLoggedInState(false);
  }
};

interface RequireAuthProps {
  children: JSX.Element;
  permission: string;
  checkSession?: boolean;
}

export const RequireAuth = ({
  children,
  permission,
  checkSession = true
}: RequireAuthProps) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingLoggedInState, setCheckingLoggedInState] = useState(true);
  const [authorised, setAuthorised] = useState(false);
  const { isLoggedIn, getToken } = useAuthenticationContext();
  const { locationId, sessionId } = useSessionContext();
  useEffect(() => {
    (async () => {
      await checkLoggedInState(
        setLoggedIn,
        setCheckingLoggedInState,
        setAuthorised,
        isLoggedIn
      );
    })();
  }, [isLoggedIn]);

  useEffect(() => {
    if (loggedIn) {
      validatePermissions(setAuthorised, permission, getToken());
    }
  }, [getToken, loggedIn, permission]);

  if (checkingLoggedInState) {
    return <h1> A few more seconds...</h1>;
  }

  if (checkSession) {
    if (locationId === 0 || sessionId === '') {
      return <Navigate to={'/home'} />;
    }
  }

  if (loggedIn) {
    if (authorised) {
      return children;
    }
    return <Navigate to={'/not-authorized'} />;
  }

  return <Navigate to={'/'} />;
};
