import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { CurrentProductViewing } from 'src/types';
import { Countdown } from './Countdown';
import { useMinuteCountdown } from 'src/hooks/use-minute-countdown/useMinuteCountdown';
import { useEffect, useState } from 'react';
import { ProductViewingStatus } from 'src/types/enums/ProductViewingStatus';
import { ProductRatingDialog } from './ProductRatingDialog';

interface ICurrentProduct {
  product: CurrentProductViewing;
}

export const CurrentProduct = ({ product }: ICurrentProduct) => {
  const countdownProps = useMinuteCountdown(
    product.startTime,
    product.finishTime
  );

  const { remainingTime, reduceTime } = countdownProps;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (remainingTime > 0) {
        reduceTime();
      }
    }, 60000);

    return () => clearTimeout(timeout);
  }, [remainingTime, reduceTime]);

  const getProductStatus = () => {
    if (!product.productViewingStatusId) {
      return 'Unkown';
    }
    if (product.productViewingStatusId === ProductViewingStatus.Returning) {
      return 'Returning';
    }
    if (product.productViewingStatusId === ProductViewingStatus.Requesting) {
      return 'Requesting';
    }
    if (product.productViewingStatusId === ProductViewingStatus.Viewing) {
      return 'Now Viewing';
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="stretch">
          <Box>
            <Countdown {...countdownProps} />
          </Box>
          <Box flexGrow={1} ml={3}>
            <Typography>{product.productName}</Typography>
            <Typography variant="body2">{product.lotNo}</Typography>
            <Typography mt={1} color="text.secondary">
              {getProductStatus()}
            </Typography>
          </Box>
        </Box>
        <Button
          sx={{ marginTop: 3, paddingTop: 1.5, paddingBottom: 1.5 }}
          fullWidth
          variant="contained"
          onClick={handleClickOpen}
        >
          Review
        </Button>
        <ProductRatingDialog
          open={open}
          handleClickClose={handleClickClose}
          product={product}
        />
      </CardContent>
    </Card>
  );
};
