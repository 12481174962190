import CancelIcon from '@mui/icons-material/Cancel';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useProductsContext, useSessionContext } from 'src/common';
import { useProductViewingsService } from 'src/services';
import { ViewingsContainerEndViewingDialog } from '../ViewingsContainerEndViewingDialog';

import { LoadingButton } from '@mui/lab';
import { useNotificationDrawerContext } from 'src/common/notification-drawer-context-provider';
import { CurrentProductViewing } from 'src/types';
import { SwipableNotificationDrawer } from './SwipableNotificationDrawer';

interface ViewingsToolbarProps {
  subscription: string | null;
  showHistory: boolean;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  currentProducts: CurrentProductViewing[];
}

export const ViewingsToolbar = ({
  subscription,
  showHistory,
  setShowHistory,
  currentProducts
}: ViewingsToolbarProps) => {
  const [open, setOpen] = useState(false);
  const { setHasRequestedProducts } = useProductsContext();
  const { sessionId, customerName, roomName } = useSessionContext();
  const { getCurrentProductRequesting } = useProductViewingsService();

  const { drawerOpen, toggleDrawer, notificationCount, setNotificationCount } =
    useNotificationDrawerContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = async () => {
    setIsLoading(true);
    const requestingProducts = await getCurrentProductRequesting(sessionId);
    setHasRequestedProducts(requestingProducts);
    setOpen(true);
    setIsLoading(false);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const showHistoryClick = () => {
    setShowHistory(!showHistory);
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        <Box
          component="img"
          sx={{
            height: 60,
            width: 60,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 }
          }}
          alt="DeBeersLogo"
          src={require('src/assets/DeBeers.jpg')}
        />
      </Grid>
      <Grid item xs={4}>
        <Box my={3}>
          <Typography
            sx={{
              letterSpacing: 1,
              fontSize: '1rem',
              fontWeight: 'bold'
            }}
          >
            {roomName} - {customerName}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <LoadingButton
          size="medium"
          color="secondary"
          variant="contained"
          startIcon={<CancelIcon />}
          sx={{
            fontSize: '0.9rem',
            marginRight: 2
          }}
          onClick={handleClickOpen}
          loading={isLoading}
        >
          End Viewing
        </LoadingButton>
        <ViewingsContainerEndViewingDialog
          open={open}
          handleClickClose={handleClickClose}
        />

        {currentProducts.length === 0 ? (
          <LoadingButton
            size="medium"
            variant="contained"
            color="secondary"
            startIcon={<HistoryIcon />}
            sx={{
              fontSize: '0.9rem'
            }}
            onClick={showHistoryClick}
          >
            View History
          </LoadingButton>
        ) : (
          <></>
        )}

        <IconButton onClick={toggleDrawer(true)}>
          <Badge badgeContent={notificationCount} color="secondary">
            <NotificationsIcon sx={{ color: '#9e9e9e', marginLeft: 4 }} />
          </Badge>
        </IconButton>

        <SwipableNotificationDrawer
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
          setNotificationCount={setNotificationCount}
          subscription={subscription}
        />
      </Grid>
    </Grid>
  );
};
