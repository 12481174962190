import { ThemeOptions } from '@mui/material';

export const stylesOverride: ThemeOptions = {
  palette: {
    primary: {
      main: '#12233A'
    },
    secondary: {
      main: '#0077BF'
    },
    success: {
      main: '#92C044'
    },
    action: {
      disabledBackground: '#6F7E8C',
      disabled: '#6F7E8C'
    },
    background: {
      default: '#f5f5f5',
      paper: '#f5f5f5'
    }
  },
  typography: {
    fontFamily: 'Futura Std Medium'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          letterSpacing: 2
        }
      }
    },
    MuiCard: {
      defaultProps: {
        square: true
      }
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        variant: 'filled'
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    }
  }
};
