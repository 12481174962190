import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  styled,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLotImageService } from 'src/services/lot-image-service';

interface LotImageDialogProps {
  lotImageId: number;
  lotNumber: string;
  open: boolean;
  handleClickClose: () => void;
}

export const LotImageDialog = ({
  lotImageId,
  lotNumber,
  open,
  handleClickClose
}: LotImageDialogProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const { getLotImageById } = useLotImageService();
  const [errorMessage, setErrorMessage] = useState('');

  const Img = styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  });

  const renderContent = () => {
    if (errorMessage !== '') {
      return <Typography>{errorMessage}</Typography>;
    }

    if (imageUrl === '') {
      <CircularProgress variant="indeterminate" />;
    }

    return <Img src={imageUrl} alt="" />;
  };

  useEffect(() => {
    (async () => {
      if (lotImageId !== 0) {
        try {
          const imgResponse = await getLotImageById(lotImageId);
          const urlCreator = window.URL || window.webkitURL;
          if (imgResponse !== null || imgResponse !== undefined) {
            setImageUrl(urlCreator.createObjectURL(imgResponse));
          }
        } catch (err) {
          setErrorMessage('The specified image could not be found');
        }
      }
    })();

    return () => setImageUrl('');
  }, [lotImageId, getLotImageById]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClickClose}
        PaperProps={{
          sx: { maxWidth: '80vw', height: '80vh', width: '80vw' }
        }}
      >
        <DialogTitle>Lot {lotNumber}</DialogTitle>
        <DialogContent>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
          >
            {renderContent()}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            onClick={() => {
              handleClickClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
