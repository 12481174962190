import { Box, Grid, Grow } from '@mui/material';
import { CurrentProductViewing } from 'src/types';
import { CurrentProduct } from './CurrentProduct';

interface ICurrentProductsArea {
  currentProducts: CurrentProductViewing[];
}

export const CurrentProductsArea = ({
  currentProducts
}: ICurrentProductsArea) => {
  if (currentProducts.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        height: '100%'
      }}
      pt={2}
    >
      <Grid container spacing={2} mt={0}>
        {currentProducts.map((product) => (
          <Grow
            in={true}
            unmountOnExit
            mountOnEnter
            timeout={500}
            key={product.productId}
          >
            <Grid item xs={6}>
              <CurrentProduct product={product} />
            </Grid>
          </Grow>
        ))}
      </Grid>
    </Box>
  );
};
