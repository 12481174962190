import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { SessionUpdate, Session } from '../types';

export const useSessionService = () => {
  const { auctionsApi } = useServiceProvider();

  const createSession = useCallback(
    async (session: Session): Promise<string> => {
      const response: AxiosResponse<string> = await auctionsApi.post(
        '/Sessions',
        session,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const updateSession = useCallback(
    async (sessionUpdate: SessionUpdate): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.put(
        `/Sessions?id=${sessionUpdate.sessionId ?? ''}`,
        sessionUpdate,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const confirmSession = useCallback(
    async (code: string): Promise<AxiosResponse<Session>> => {
      const response: AxiosResponse<Session> = await auctionsApi.get(
        `Sessions/Confirm?code=${code}`
      );
      return response;
    },
    [auctionsApi]
  );

  const getSessionById = useCallback(
    async (sessionId: string): Promise<Session> => {
      const response: AxiosResponse<Session> = await auctionsApi.get(
        '/Sessions',
        {
          params: {
            sessionId
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  return { createSession, updateSession, confirmSession, getSessionById };
};
