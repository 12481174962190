import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Container,
  createTheme,
  IconButton,
  Slide,
  Tab,
  Tabs,
  Theme,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import {
  TabPanel,
  useProductsContext,
  useServiceProvider,
  useSessionContext
} from 'src/common';
import { useSignalR } from 'src/hooks/use-signal-r/useSignalR';
import { renderDate } from 'src/lib/date-format/render-date';
import { useProductViewingsService } from 'src/services';
import { PaginatedResponse, Request, TableRowData } from 'src/types';
import useSound from 'use-sound';
import { useSessionService } from '../../services/session-service';
import NotificationSound from '../../sounds/NotificationSound.mp3';
import { CreateRequest } from './components/CreateRequest';
import { CurrentProductsArea } from './components/CurrentProductsArea';
import { PendingRequests } from './components/PendingRequests';
import { ProductViewingHistoryArea } from './components/ProductViewingHistoryArea';
import { ViewingsToolbar } from './components/ViewingsToolbar';

export const ViewingsContainer = () => {
  const sideGutterSize = 10;
  const {
    currentProducts,
    setCurrentProducts,
    pendingProducts,
    setPendingProducts
  } = useProductsContext();
  const {
    clearLocalStorage,
    sessionId,
    location,
    customerId,
    maxProducts,
    setCustomerName,
    setRoomName
  } = useSessionContext();
  const {
    getCurrentProductViewings,
    getPendingRequest,
    getProductViewingsHistory
  } = useProductViewingsService();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { getSessionById } = useSessionService();
  const { stopListening } = useServiceProvider();
  const {
    notification,
    subscription,
    declineNotification,
    acceptNotification
  } = useSignalR(location);
  const [play] = useSound(NotificationSound as string);
  const [totalHistoryCount, setTotalHistoryCount] = useState(0);
  const [value, setValue] = useState(0);
  const [currentSalesCycle, setCurrentSalesCycle] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  useEffect(() => {
    if (subscription !== null) {
      play();
      enqueueSnackbar('New products from your subscriptions are available!', {
        variant: 'info'
      });
    }
  }, [enqueueSnackbar, subscription, play]);

  useEffect(() => {
    if (acceptNotification !== null) {
      enqueueSnackbar('Your request has been accepted!', {
        variant: 'info'
      });
    }
  }, [enqueueSnackbar, acceptNotification]);

  useEffect(() => {
    (async () => {
      const sessionCheck = await getSessionById(sessionId);
      if (sessionCheck.sessionStatusId === 3) {
        clearLocalStorage();
        await stopListening(location, sessionId);
      } else {
        setCustomerName(sessionCheck.customerName);
        setRoomName(sessionCheck.roomName);
      }
    })();
  }, [
    sessionId,
    clearLocalStorage,
    getSessionById,
    location,
    stopListening,
    setCustomerName,
    setRoomName
  ]);

  useEffect(() => {
    const action = (snackbarId: SnackbarKey) => (
      <>
        <Button
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          Dismiss
        </Button>
      </>
    );
    if (declineNotification !== null) {
      enqueueSnackbar('Your request has been declined.', {
        action,
        variant: 'warning',
        persist: true
      });
    }
  }, [enqueueSnackbar, declineNotification, closeSnackbar]);

  useEffect(() => {
    (async () => {
      const viewings = await getCurrentProductViewings(sessionId);
      setCurrentProducts(viewings);
    })();
  }, [sessionId, setCurrentProducts, notification, getCurrentProductViewings]);

  useEffect(() => {
    (async () => {
      const pendingResponse = await getPendingRequest(sessionId);
      setPendingProducts(pendingResponse);
    })();
  }, [getPendingRequest, sessionId, notification, setPendingProducts]);

  const innerTheme = (theme: Theme) =>
    createTheme({
      ...theme,
      typography: {
        fontFamily: 'Futura Std Medium'
      },
      components: {
        ...theme.components,
        MuiTypography: {
          defaultProps: {
            color: '#f5f5f5'
          }
        }
      }
    });

  const heightWithoutProducts = useCallback(
    (heightWith: string, heightWithout: string) => {
      if (currentProducts.length === 0 && !showHistory) {
        return heightWith;
      }
      return heightWithout;
    },
    [currentProducts, showHistory]
  );

  const fetchRows = useCallback(
    async ({
      page,
      pageSize,
      filters = { customerId: String(customerId) }
    }: Request): Promise<PaginatedResponse<TableRowData>> => {
      const paginatedResponse = await getProductViewingsHistory({
        page,
        pageSize,
        filters
      });
      const tableRows = paginatedResponse.items
        ? paginatedResponse.items.map((item) => {
            return {
              ...item,
              sessionDate: renderDate(new Date(item.sessionDate)),
              lotNo: item.lotNo,
              productName: item.productName,
              totalViewingDuration: item.totalViewingDuration
            };
          })
        : [];
      setCurrentSalesCycle(
        paginatedResponse.items
          ? paginatedResponse.items[0]
            ? paginatedResponse.items[0].salesCycleName ?? ''
            : ''
          : ''
      );
      setTotalHistoryCount(paginatedResponse.totalCount);
      return { ...paginatedResponse, items: tableRows };
    },
    [getProductViewingsHistory, customerId]
  );

  useEffect(() => {
    (async () => {
      await fetchRows({
        page: 0,
        pageSize: 10,
        filters: { customerId: String(customerId) }
      });
    })();
  }, [fetchRows, customerId, currentProducts]);

  const handleClick = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <Div100vh>
      <Box
        sx={{
          height: '100vh',
          maxHeight: '100%',
          backgroundColor: (theme) => theme.palette.grey[200]
        }}
      >
        <Box
          sx={{
            height: '100%'
          }}
          display="flex"
        >
          <Box flexGrow={4} sx={{ height: '100%' }}>
            <ThemeProvider theme={innerTheme}>
              <Box
                sx={{
                  backgroundColor: '#12233A',
                  height: heightWithoutProducts('100%', '60%'),
                  transition: (theme: Theme) =>
                    theme.transitions.create('height')
                }}
              >
                <Container sx={{ height: '100%' }}>
                  <Box
                    mx={sideGutterSize}
                    pt={4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    sx={{
                      height: heightWithoutProducts('80%', '100%')
                    }}
                  >
                    <ViewingsToolbar
                      subscription={subscription}
                      showHistory={showHistory}
                      setShowHistory={setShowHistory}
                      currentProducts={currentProducts}
                    />

                    <Box flexGrow={2}>
                      <CreateRequest>
                        <Button
                          color="secondary"
                          variant="contained"
                          id="create-request-button"
                          fullWidth
                          sx={{
                            fontSize: '1rem',
                            paddingTop: 1.5,
                            paddingBottom: 1.5
                          }}
                        >
                          {pendingProducts.length > 0
                            ? 'View Catalogue'
                            : 'Create Request'}
                        </Button>
                      </CreateRequest>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </ThemeProvider>
            {((currentProducts && currentProducts.length > 0) ||
              showHistory) && (
              <Container id="viewings-area">
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ marginBottom: -2, paddingTop: 2 }}>
                    <Tabs
                      centered
                      value={value}
                      onChange={handleChange}
                      aria-label="Viewings Tabs"
                      TabIndicatorProps={{
                        style: { backgroundColor: '#0077BF', height: '2px' }
                      }}
                    >
                      <Tab
                        label={
                          <Typography
                            sx={{
                              letterSpacing: 1,
                              fontSize: '1rem',
                              fontWeight: 500
                            }}
                          >
                            Now Viewing ({currentProducts.length}/{maxProducts})
                          </Typography>
                        }
                        {...a11yProps(0)}
                        sx={{
                          width: '330px'
                        }}
                      />
                      <Tab
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                letterSpacing: 1,
                                fontSize: '1rem',
                                fontWeight: 500
                              }}
                            >
                              History ({totalHistoryCount})
                            </Typography>
                            <Tooltip
                              title="View the history of each lot you have looked at in this sales cycle."
                              open={showTooltip}
                              onOpen={() => setShowTooltip(true)} //do this to ensure you can view the tooltip when you click the info icon on mobile
                              onClose={() => setShowTooltip(false)}
                            >
                              <IconButton size="small" onClick={handleClick}>
                                <InfoIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        }
                        {...a11yProps(1)}
                        sx={{
                          width: '330px'
                        }}
                      />
                    </Tabs>
                  </Box>
                </Box>
                <TabPanel value={value} index={0}>
                  <CurrentProductsArea currentProducts={currentProducts} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {totalHistoryCount !== 0 ? (
                    <ProductViewingHistoryArea
                      fetchRows={fetchRows}
                      currentSalesCycle={currentSalesCycle}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="body1">
                        {'You do not have any history for the sales cycle yet.'}
                      </Typography>
                    </Box>
                  )}
                </TabPanel>
              </Container>
            )}
          </Box>
          <Slide
            in={pendingProducts.length > 0}
            direction="left"
            mountOnEnter
            unmountOnExit
          >
            <Box sx={{ height: '100%' }} flexGrow={1}>
              <PendingRequests products={pendingProducts} />
            </Box>
          </Slide>
        </Box>
      </Box>
    </Div100vh>
  );
};

export default ViewingsContainer;
