import { Grid, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const EndViewingContainer = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      style={{ minHeight: '100vh', backgroundColor: '#12233A' }}
    >
      <Grid item>
        <Box
          component="img"
          sx={{
            height: 80,
            width: 80,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 }
          }}
          alt="DeBeersLogo"
          src={require('../../assets/DeBeers.jpg')}
        />
      </Grid>

      <Grid item>
        <Typography color={'#f5f5f5'} variant="h5">
          De Beers Group
        </Typography>
      </Grid>

      <Grid item mt={1}>
        <Typography color="#f5f5f5" variant="h4">
          Auctions
        </Typography>
      </Grid>

      <Grid item mt={2}>
        <Typography color="#f5f5f5" variant="h6">
          Your session has been ended.
        </Typography>
        <Typography color="#f5f5f5" variant="subtitle1">
          Please wait for a KAM to assist with the rest of the checkout process.
        </Typography>
      </Grid>

      <Grid item mt={2} mb={2} sx={{ width: '200px' }}>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              fontSize: '15px',
              fontWeight: 'light',
              borderRadius: 0,
              paddingTop: 2,
              paddingBottom: 2
            }}
          >
            Finish
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default EndViewingContainer;
