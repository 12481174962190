import { AxiosError, AxiosResponse } from 'axios';
import {
  CurrentProductViewing,
  ItemType,
  ProductViewingHistory,
  PaginatedResponse,
  Request
} from 'src/types';
import {
  ProductRequest,
  ProductPendingRequest,
  ProductViewingsRequest
} from 'src/types';
import { toUTC } from 'src/lib';
import { useServiceProvider } from 'src/common';
import { useCallback } from 'react';
import { get } from 'lodash';

export const useProductViewingsService = () => {
  const { auctionsApi } = useServiceProvider();

  const createProductViewings = useCallback(
    async (productViewingsRequest: ProductViewingsRequest) => {
      const response: AxiosResponse<CurrentProductViewing[]> =
        await auctionsApi.post('/ProductViewings', productViewingsRequest, {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        });
      return response.data;
    },
    [auctionsApi]
  );

  const deleteProductViewing = useCallback(
    async (productId: number, locationName: string) => {
      const response: AxiosResponse<number> = await auctionsApi.delete(
        '/ProductViewings',
        {
          params: { productId, locationName },
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const updateProductViewingForSubmit = useCallback(
    async (productViewingsRequest: ProductViewingsRequest) => {
      try {
        return await auctionsApi.put(
          '/ProductViewings/Submit',
          productViewingsRequest,
          {
            headers: {
              accept: 'text/plain',
              contentType: 'application/json'
            }
          }
        );
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const updateProductViewingForEnd = useCallback(
    async (productViewingsRequest: ProductViewingsRequest) => {
      try {
        return await auctionsApi.put(
          '/ProductViewings/End',
          productViewingsRequest,
          {
            headers: {
              accept: 'text/plain',
              contentType: 'application/json'
            }
          }
        );
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const getCurrentProductViewings = useCallback(
    async (sessionId: string) => {
      const response: AxiosResponse<CurrentProductViewing[]> =
        await auctionsApi.get('/ProductViewings', {
          params: { sessionId }
        });

      return response.data.map((product) => ({
        ...product,
        startTime: toUTC(new Date(product.startTime)),
        finishTime: toUTC(new Date(product.finishTime))
      }));
    },
    [auctionsApi]
  );

  const getCurrentProductRequesting = useCallback(
    async (sessionId: string) => {
      const response: AxiosResponse<CurrentProductViewing[]> =
        await auctionsApi.get('/ProductViewings/RequestingProducts', {
          params: { sessionId }
        });

      return response.data.map((product) => ({
        ...product,
        startTime: toUTC(new Date(product.startTime)),
        finishTime: toUTC(new Date(product.finishTime))
      }));
    },
    [auctionsApi]
  );

  const getReturnProducts = useCallback(
    async (sessionId: string): Promise<ProductRequest[]> => {
      const response: AxiosResponse<ProductRequest[]> = await auctionsApi.get(
        '/ProductViewings/ReturnProducts',
        {
          params: { sessionId }
        }
      );

      return response.data.map((product) => ({
        ...product,
        productType: ItemType.ReturnItem
      }));
    },
    [auctionsApi]
  );

  const getPendingRequest = useCallback(
    async (sessionId: string): Promise<ProductPendingRequest[]> => {
      const response: AxiosResponse<ProductPendingRequest[]> =
        await auctionsApi.get('/ProductViewings/CustomerProductRequest', {
          params: {
            sessionId
          }
        });

      return response.data;
    },
    [auctionsApi]
  );

  const getProductViewingsHistory = useCallback(
    async ({
      page,
      pageSize,
      filters
    }: Request): Promise<PaginatedResponse<ProductViewingHistory>> => {
      const response: AxiosResponse<PaginatedResponse<ProductViewingHistory>> =
        await auctionsApi.get('/ProductViewings/ViewingsHistory', {
          params: {
            page,
            pageSize,
            customerId: get(filters, 'customerId', '')
          }
        });
      return response.data;
    },
    [auctionsApi]
  );

  return {
    createProductViewings,
    deleteProductViewing,
    updateProductViewingForSubmit,
    updateProductViewingForEnd,
    getCurrentProductViewings,
    getReturnProducts,
    getCurrentProductRequesting,
    getPendingRequest,
    getProductViewingsHistory
  };
};
