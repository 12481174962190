import { createContext, PropsWithChildren, useContext } from 'react';
import { validateEnv } from 'src/lib';
import { Env } from 'src/types';

declare global {
  interface Window {
    env: Env;
  }
}

const EnvContext = createContext<Env | undefined>(undefined);
EnvContext.displayName = 'Environment Config';

export const EnvProvider = ({
  config,
  children
}: PropsWithChildren<{ config: Partial<Env> }>) => {
  const windowConfig = window?.env ?? {};

  const envConfig = validateEnv(windowConfig, config);

  return (
    <EnvContext.Provider value={envConfig}>{children}</EnvContext.Provider>
  );
};

export const useEnv = () => {
  const context = useContext(EnvContext);

  if (!context) {
    throw new Error(
      'Env Context cannot be used outside of Env Context Provider'
    );
  }

  return context;
};
