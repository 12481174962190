export enum ProductViewingStatus {
  On_Hold = 1,
  Returning = 2,
  Requesting = 3,
  Viewing = 4
}

export enum ProductViewingStatusStrings {
  On_Hold = 'On Hold',
  Returning = 'Returning',
  Requesting = 'Requesting',
  Viewing = 'Viewing'
}
