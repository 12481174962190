import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Rating,
  TextField,
  Typography
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSessionContext } from 'src/common';
import { useProductRatingsService } from 'src/services/product-rating-service';
import { CurrentProductViewing } from 'src/types';

interface IProductRatingDialog {
  open: boolean;
  handleClickClose: () => void;
  product: CurrentProductViewing;
}

export const ProductRatingDialog = ({
  open,
  handleClickClose,
  product
}: IProductRatingDialog) => {
  const [valueModel, setValueModel] = React.useState<number>(0);
  const [valueFluorescence, setValueFluorescence] = React.useState<number>(0);
  const [valueColour, setValueColour] = React.useState<number>(0);
  const [valueClarity, setValueClarity] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const commentLimit = 500;
  const [comment, setComment] = React.useState('');
  const { sessionId } = useSessionContext();
  const { createProductRatings } = useProductRatingsService();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (comment: React.ChangeEvent<HTMLInputElement>) => {
    setComment(comment.target.value);
  };

  const submitProductRating = async () => {
    setIsLoading(true);
    const productRating = {
      sessionId,
      productId: product.productId,
      model: valueModel,
      fluorescence: valueFluorescence,
      colour: valueColour,
      clarity: valueClarity,
      comment: comment
    };
    try {
      await createProductRatings(productRating);

      enqueueSnackbar('Rating successfully submitted', {
        variant: 'success'
      });

      handleClickClose();
    } catch (error) {
      const err = error as AxiosError;

      if (err.message === 'Network Error') {
        enqueueSnackbar('Network Error', {
          variant: 'error'
        });
      }
      handleClickClose();
    }
    setIsLoading(false);
  };

  const disabled =
    valueModel === 0 &&
    valueFluorescence === 0 &&
    valueColour === 0 &&
    valueClarity === 0 &&
    comment.length <= 0;

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClickClose}
        PaperProps={{ sx: { minWidth: '600px', maxWidth: '600px' } }}
      >
        <Typography variant="h5" color="primary" sx={{ pt: 3, pl: 3, pb: 2 }}>
          Product Rating
        </Typography>

        <Typography variant="h6" sx={{ pl: 3, pt: 1 }}>
          {product.productName}
        </Typography>
        <Typography variant="subtitle1" sx={{ pl: 3 }}>
          {product.lotNo}
        </Typography>

        <DialogContent>
          <Box component="form">
            <Grid container justifyContent="space-evenly">
              <Grid item xs={6}>
                <Typography component="legend">Model</Typography>
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={valueModel}
                  onChange={(_event, newValue) => {
                    setValueModel(newValue ?? 0);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography component="legend">Fluorescence</Typography>
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={valueFluorescence}
                  onChange={(_event, newValue) => {
                    setValueFluorescence(newValue ?? 0);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography component="legend">Colour</Typography>
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={valueColour}
                  onChange={(_event, newValue) => {
                    setValueColour(newValue ?? 0);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography component="legend">Clarity</Typography>
                <Rating
                  name="simple-controlled"
                  size="large"
                  value={valueClarity}
                  onChange={(_event, newValue) => {
                    setValueClarity(newValue ?? 0);
                  }}
                />
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: 550,
                    maxWidth: '100%'
                  }}
                  mt={2}
                >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Comment"
                    multiline
                    maxRows={4}
                    fullWidth
                    inputProps={{ maxLength: commentLimit }}
                    value={comment}
                    helperText={`${comment.length}/${commentLimit}`}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: 3 }}>
          <Button
            sx={{ width: '30%', fontSize: '1rem' }}
            onClick={handleClickClose}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={disabled}
            variant="contained"
            sx={{ width: '30%', fontSize: '1rem' }}
            onClick={submitProductRating}
            loading={isLoading}
            loadingIndicator={<CircularProgress color="primary" size={16} />}
          >
            Complete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
