import { useProductsContext } from 'src/common';

export const PendingWrapper = ({ children }: { children: JSX.Element }) => {
  const { pendingProducts } = useProductsContext();
  if (pendingProducts.length > 0) {
    return null;
  } else {
    return children;
  }
};
