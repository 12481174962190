import { Box, Divider, List, SwipeableDrawer, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useProductsContext, useSessionContext } from 'src/common';
import { useProductNotificationService } from 'src/services/product-notification-service';
import { ProductNotification } from 'src/types';
import { ProductNotificationListItem } from './ProductNotificationListItem';

interface SwipableNotificationDrawerProps {
  open: boolean;
  subscription: string | null;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  setNotificationCount: (notificationCount: number) => void;
}

export const SwipableNotificationDrawer = ({
  open,
  toggleDrawer,
  subscription,
  setNotificationCount
}: SwipableNotificationDrawerProps) => {
  const {
    productNotifications,
    setProductNotifications,
    removeProductNotification
  } = useProductsContext();
  const { sessionId } = useSessionContext();
  const { getProductNotificationsList, deleteProductNotification } =
    useProductNotificationService();

  const availableNotifications = useMemo(
    () =>
      productNotifications.filter(
        (productNotification) => productNotification.isAvailable
      ),
    [productNotifications]
  );

  const unavailableNotifications = useMemo(
    () =>
      productNotifications.filter(
        (productNotification) => !productNotification.isAvailable
      ),
    [productNotifications]
  );

  useEffect(() => {
    (async () => {
      const productNotificationsListResponse =
        await getProductNotificationsList(sessionId);
      if (productNotificationsListResponse !== undefined) {
        setProductNotifications(
          productNotificationsListResponse.map((obj) => ({
            ...obj,
            isLoading: false
          }))
        );
        setNotificationCount(
          productNotificationsListResponse.filter(
            (productNotification) => productNotification.isAvailable
          ).length
        );
      }
    })();
  }, [
    getProductNotificationsList,
    sessionId,
    subscription,
    open,
    setNotificationCount,
    setProductNotifications
  ]);

  const handleUnsubscribeFromNotification = async (
    productNotificationPassed: ProductNotification
  ) => {
    await deleteProductNotification(
      sessionId,
      productNotificationPassed.productId
    );
    setProductNotifications((currentList) =>
      currentList.filter(
        (productNotification) =>
          productNotification.productId !== productNotificationPassed.productId
      )
    );
    removeProductNotification(productNotificationPassed.productId);
  };

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      id="notification-drawer"
    >
      <Box
        sx={{ width: 400 }}
        role="presentation"
        onKeyDown={toggleDrawer(false)}
      >
        <Typography variant="h6" style={{ color: 'black', padding: 12 }}>
          NOTIFICATION SUBSCRIPTIONS
        </Typography>
        <Divider />
        <Typography style={{ color: 'black', padding: 12 }}>
          Available Products
        </Typography>
        <Divider />
        <List>
          {availableNotifications.length === 0 ? (
            <Typography
              variant="body2"
              style={{ color: 'black', padding: 12, fontStyle: 'italic' }}
            >
              Products that you have subscribed to that become available will
              appear here
            </Typography>
          ) : (
            availableNotifications.map((myNotification) => (
              <ProductNotificationListItem
                key={myNotification.productId}
                productNotification={myNotification}
                handleUnsubscribeFromNotification={
                  handleUnsubscribeFromNotification
                }
              />
            ))
          )}
        </List>
        <Typography style={{ color: 'black', padding: 12 }}>
          Unavailable Products
        </Typography>
        <Divider />
        <List>
          {unavailableNotifications.length === 0 ? (
            <Typography
              variant="body2"
              style={{ color: 'black', padding: 12, fontStyle: 'italic' }}
            >
              Products that you are subscribed to that are unavailable will
              appear here
            </Typography>
          ) : (
            unavailableNotifications.map((myNotification) => (
              <ProductNotificationListItem
                key={myNotification.productId}
                productNotification={myNotification}
                handleUnsubscribeFromNotification={
                  handleUnsubscribeFromNotification
                }
              />
            ))
          )}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};
