import { useSnackbar } from 'notistack';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';
import { useProductList } from 'src/hooks/use-product-list';
import {
  CurrentProductViewing,
  ProductNotificationResponse,
  ProductPendingRequest
} from 'src/types';
import { Product } from 'src/types/models/Product.type';
import { useSessionContext } from '../session-context-provider/SessionContextProvider';
import { usePersistedProductList } from 'src/hooks/use-product-list/usePersistedProductList';
import { useProductViewingsService } from 'src/services';

interface IProductsContextProvider {
  selectedProducts: Product[];
  returningProducts: Product[];
  productNotifications: ProductNotificationResponse[];
  onHoldProducts: CurrentProductViewing[];
  currentProducts: CurrentProductViewing[];
  hasRequestedProducts: CurrentProductViewing[];
  currentProductSlots: number;
  clearRequestItems: (releaseHold?: boolean) => Promise<void>;
  addSelectedProduct: (product: Product) => void;
  addReturningProduct: (product: Product) => void;
  addProductNotification: (product: ProductNotificationResponse) => void;
  removeProductFromSelection: (productId: number, notify?: boolean) => void;
  removeReturningProduct: (productId: number) => void;
  removeProductNotification: (productId: number) => void;
  removeOnHoldProduct: (productId: number) => void;
  isInRequestList: (productId: number) => boolean;
  isInNotificationList: (productId: number) => boolean;
  isInOnHoldList: (productId: number) => boolean;
  setCurrentProducts: (newList: CurrentProductViewing[]) => void;
  setHasRequestedProducts: (newList: CurrentProductViewing[]) => void;
  setOnHoldProducts: (newList: CurrentProductViewing[]) => void;
  setProductNotifications: Dispatch<
    SetStateAction<ProductNotificationResponse[]>
  >;
  setSelectedProducts: Dispatch<SetStateAction<Product[]>>;
  pendingProducts: ProductPendingRequest[];
  setPendingProducts: (pendingProducts: ProductPendingRequest[]) => void;
}

const ProductsContext = createContext<IProductsContextProvider | undefined>(
  undefined
);

export const ProductsContextProvider: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { maxProducts, location } = useSessionContext();
  const { deleteProductViewing } = useProductViewingsService();

  const [
    selectedProducts,
    setSelectedProducts,
    addSelectedProduct,
    removeSelectedProduct
  ] = useProductList();

  const [
    returningProducts,
    setReturningProducts,
    addReturningProduct,
    removeReturningProduct
  ] = usePersistedProductList();

  const [hasRequestedProducts, setHasRequestedProducts] =
    useProductList<CurrentProductViewing>();

  const [onHoldProducts, setOnHoldProducts, , removeOnHoldProduct] =
    useProductList<CurrentProductViewing>();

  const [
    productNotifications,
    setProductNotifications,
    addProductNotification,
    removeProductNotification
  ] = useProductList<ProductNotificationResponse>([]);

  const [currentProducts, setCurrentProducts] = useState<
    CurrentProductViewing[]
  >([]);

  const [pendingProducts, setPendingProducts] = useState<
    ProductPendingRequest[]
  >([]);

  const currentProductSlots = useMemo(() => {
    return (
      maxProducts -
      currentProducts.length -
      selectedProducts.length +
      returningProducts.length
    );
  }, [
    currentProducts.length,
    maxProducts,
    selectedProducts,
    returningProducts
  ]);

  const removeProductFromSelection = (productId: number, notify = false) => {
    const productToRemove = selectedProducts.find(
      (product) => product.productId === productId
    );

    removeSelectedProduct(productId);

    if (notify && productToRemove) {
      enqueueSnackbar(
        `${productToRemove.productName} (${productToRemove.lotNumber}) is no longer available`,
        {
          variant: 'error',
          preventDuplicate: true,
          key: `${productToRemove.productId}`
        }
      );
    }
  };

  const isInRequestList = (productId: number) => {
    return returningProducts.some((product) => product.productId === productId);
  };

  const isInNotificationList = (productId: number) => {
    const checkProductId = (product: ProductNotificationResponse) =>
      product.productId === productId;
    return productNotifications.some(checkProductId);
  };

  const isInOnHoldList = (productId: number) => {
    return onHoldProducts.some((product) => product.productId === productId);
  };

  const clearRequestItems = async (releaseHold = false) => {
    setReturningProducts([]);
    setSelectedProducts([]);
    if (releaseHold) {
      for (const product of onHoldProducts) {
        await deleteProductViewing(product.productId, location);
      }
    }

    setOnHoldProducts([]);
  };

  return (
    <ProductsContext.Provider
      value={{
        addSelectedProduct,
        returningProducts,
        addReturningProduct,
        addProductNotification,
        removeProductNotification,
        removeProductFromSelection,
        removeReturningProduct,
        isInRequestList,
        isInOnHoldList,
        productNotifications,
        isInNotificationList,
        currentProductSlots,
        selectedProducts,
        onHoldProducts,
        setOnHoldProducts,
        removeOnHoldProduct,
        currentProducts,
        setCurrentProducts,
        hasRequestedProducts,
        setHasRequestedProducts,
        pendingProducts,
        setPendingProducts,
        clearRequestItems,
        setSelectedProducts,
        setProductNotifications
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  const productsContext = useContext(ProductsContext);

  if (!productsContext) {
    throw new Error(
      'Products Context cannot be used outside of Products Context Provider'
    );
  }

  return productsContext;
};
