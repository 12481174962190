import { TextField } from '@mui/material';
import { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';

interface PinFieldProps {
  completed: (pinValue: string) => Promise<void>;
}

export const PinField = ({ completed }: PinFieldProps) => {
  const [pinValue, setPinValue] = useState('----');
  const length = 4;
  const inputs = Array.from(Array(length).keys());
  const myRefs = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const checkValid = () => {
      return !(pinValue.indexOf('-') > -1);
    };
    if (checkValid()) {
      (async () => {
        await completed(pinValue);
      })();
    }
  }, [completed, pinValue]);

  const getPinValueAtIndex = (index: number) => {
    return pinValue.charAt(index) === '-' ? '' : pinValue.charAt(index);
  };

  const setValueAtIndex = (index: number, replacement: string) => {
    return (
      pinValue.substring(0, index) +
      replacement +
      pinValue.substring(index + replacement.length)
    );
  };

  const handleBackward = (
    event: KeyboardEvent<HTMLDivElement>,
    index: number
  ) => {
    const previousInput: HTMLElement =
      myRefs.current[index > 0 ? index - 1 : index];
    if (event.key === 'Backspace' && previousInput) {
      previousInput.focus();
      setPinValue(setValueAtIndex(index, '-'));
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const re = /^[0-9\b]+$/;
    const value: string = event.target.value;
    if (re.test(value)) {
      setPinValue(setValueAtIndex(index, value));
      handleForward(index);
    }
  };

  const handleForward = (index: number) => {
    const nextInput: HTMLElement = myRefs.current[index + 1];
    if (nextInput) {
      nextInput.focus();
    }
  };
  return (
    <>
      {inputs.map((input, i) => (
        <TextField
          id={input.toString()}
          key={input.toString()}
          value={getPinValueAtIndex(i)}
          onChange={(e) => handleChange(e, i)}
          onKeyDown={(e) => handleBackward(e, i)}
          inputRef={(el: HTMLElement) => (myRefs.current[Number(i)] = el)}
          fullWidth
          autoFocus={i === 0}
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'center',
                fontSize: '2rem'
              }
            }
          }}
          style={{
            height: '4rem',
            outline: 'none',
            textAlign: 'center',
            width: '5rem',
            margin: '1rem'
          }}
          variant="outlined"
          color={
            document.activeElement === myRefs.current[Number(i)]
              ? 'secondary'
              : 'primary'
          }
          focused
        />
      ))}
    </>
  );
};

export default PinField;
