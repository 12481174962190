import { useState } from 'react';
import { Product } from 'src/types';

interface K {
  productId: number;
}

export const useProductList = <T extends K = Product>(initValue: T[] = []) => {
  const [products, setProducts] = useState(initValue);

  const addProduct = (product: T) => {
    setProducts((currentList) => [...currentList, product]);
  };

  const removeProduct = (productId: number) => {
    setProducts((currentList) =>
      currentList.filter((product) => product.productId !== productId)
    );
  };

  return [products, setProducts, addProduct, removeProduct] as const;
};
