import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { LotImageMetadata } from 'src/types';

export const useLotImageService = () => {
  const { auctionsApi } = useServiceProvider();

  const getLotImageMetadata = useCallback(
    async (sessionId: string): Promise<LotImageMetadata[]> => {
      const response: AxiosResponse<LotImageMetadata[]> = await auctionsApi.get(
        '/LotImages/GetListOfLotImages',
        {
          params: {
            sessionId
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const getLotImageById = useCallback(
    async (lotImageId: number): Promise<Blob> => {
      const response: AxiosResponse<Blob> = await auctionsApi.get(
        '/LotImages/image',
        {
          params: {
            lotImageId
          },
          responseType: 'blob'
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  return { getLotImageMetadata, getLotImageById };
};
