import { Box, List, ListItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSessionContext } from 'src/common';
import { useProductViewingsService } from 'src/services';
import { ProductRequest } from 'src/types/models/ProductRequest.type';
import { ReturnProductListItem } from './ReturnProductListItem';

export const ReturnProductsContainer = () => {
  const { sessionId } = useSessionContext();
  const [returnProducts, setReturnProducts] = useState<ProductRequest[]>([]);
  const { getReturnProducts } = useProductViewingsService();

  useEffect(() => {
    (async () => {
      setReturnProducts(await getReturnProducts(sessionId));
    })();
  }, [sessionId, getReturnProducts]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 400
      }}
    >
      <List
        sx={{
          width: '100%',
          height: 380,
          position: 'relative',
          overflow: 'auto',
          maxHeight: 380,
          bgColor: 'background.paper'
        }}
      >
        {returnProducts.length > 0 ? (
          returnProducts.map((product) => (
            <ListItem
              key={product.productId}
              sx={{
                borderBottom: '2px solid #dedede',
                paddingTop: '15px',
                paddingBottom: '15px'
              }}
            >
              <ReturnProductListItem product={product} />
            </ListItem>
          ))
        ) : (
          <Box sx={{ paddingTop: 10, textAlign: 'center' }}>
            <Typography variant="subtitle1" component="h6" fontWeight={600}>
              You do not currently have products to return.
            </Typography>
          </Box>
        )}
      </List>
    </Box>
  );
};
