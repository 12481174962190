import { Product } from 'src/types';
import { useSessionStorage } from '../use-session-storage/useSessionStorage';

interface K {
  productId: number;
}

export const usePersistedProductList = <T extends K = Product>(
  initValue: T[] = []
) => {
  const [products, setProducts] = useSessionStorage<T[]>(
    'returningProducts',
    initValue
  );

  const addProduct = (product: T) => {
    setProducts((currentList) => [...currentList, product]);
  };

  const removeProduct = (productId: number) => {
    setProducts((currentList) =>
      currentList.filter((product) => product.productId !== productId)
    );
  };

  return [products, setProducts, addProduct, removeProduct] as const;
};
